import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import Layout from "./website/layout/Layout";
import Home from "./website/pages/HomeLogin";
import Registration from "./website/pages/reception/Registration";

import ErrorPage from "./admin/pages/ErrorPage";

import Admin from "./admin/routes/admin.routes";
import ConfirmationMiddleware from "./website/middleware/confirmation-middleware";
import ViewAll from "./website/pages/reception/RegistrationDashBoard";
import Optometrist from "./website/pages/optometrist/Optometrist";
import OptometristDashboard from "./website/pages/optometrist/OptometristDashboard";
import HomeMiddleware from "./middleware/home-middleware";
import AdminLoginMiddleware from "./middleware/admin-login-middleware";
import ReceptionTokenMiddleware from "./middleware/reception-token-middleware";
import OptoTokenMiddleware from "./middleware/opto-token-middleware";
import AdminTokenMiddleware from "./middleware/admin-token-middleware";
import PatientDetailById from "./website/pages/optometrist/PatientDetailById";

const loading = (
  <div className="flex justify-center h-screen items-center">
    <PropagateLoader color="#36d7b7" loading={true} size={15} />
  </div>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/admin/*" element={
        <AdminTokenMiddleware>
          <Admin />
          </AdminTokenMiddleware>
          } />
      <Route
        path="/"
        element={
          <Layout>
            <HomeMiddleware>
            <Home />
            </HomeMiddleware>
          </Layout>
        }
      />
      <Route
        path="/registration"
        element={
          <Layout>
            <ReceptionTokenMiddleware>
            <Registration />{" "}
            </ReceptionTokenMiddleware>

          </Layout>
        }
      />
      <Route
        path="/registrationDashboard"
        element={
          <Layout>
            <ReceptionTokenMiddleware>
            <ViewAll />{" "}
            </ReceptionTokenMiddleware>

          </Layout>
        }
      />
      <Route
        path="/optometrist"
        element={
          <Layout>
            <OptoTokenMiddleware>
            <Optometrist />{" "}
            </OptoTokenMiddleware>
          </Layout>
        }
      />
      <Route
        path="/optometrist_dashboard"
        element={
          <Layout>
            <OptoTokenMiddleware>
            <OptometristDashboard />{" "}
            </OptoTokenMiddleware>
          </Layout>
        }
      />
      <Route
        path="/patient-detail-by-id/:patient_id"
        element={
          <Layout>
            <OptoTokenMiddleware>
            <PatientDetailById />{" "}
            </OptoTokenMiddleware>
          </Layout>
        }
      />
      {/* <Route
        path="/my-account"
        element={
          <Layout>
            <ConfirmationMiddleware>
              <MyAccount />
            </ConfirmationMiddleware>
          </Layout>
        }
      /> */}
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} fallback={loading} />
  </Provider>
);

reportWebVitals();
