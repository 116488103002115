

/** @format */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { fetch } from "../../../service/utils";
import { useInput } from "../../../hook/input-hook";
import toast from "react-hot-toast";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Input from "../input/Input.component";
import { useParams } from "react-router-dom";
import { numberReg } from "../../../service/validations/validation";
import { MoonLoader } from "react-spinners";
import { formatDated } from "../../../service/date.utils";
import { glassesCheckBoxList } from "../../../service/constant";


export default function AddPatientMedicine({
    addPatientMedicineOpen, setAddPatientMedicineOpen,
    selectedPatient,
    getPtDetails
}) {
    const cancelButtonRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [glassesClose, setGlassesClose] = useState(false);
    const [ophthalmicClose, setOphthalmicClose] = useState(false);
    const [slitLampClose, setSlitLampClose] = useState(false);
    const [fundusClose, setFundusClose] = useState(false);
    const [followUpClose, setFollowUpClose] = useState(false);
    const [diangnoseClose, setDiangnoseClose] = useState(false);
    const [adviseClose, setAdviselose] = useState(false);


    const [networkError, setNetworkError] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");


    useEffect(() => {
        setNetworkError("");
    }, [addPatientMedicineOpen]);


    const [medications, setMedications] = useState([
        { medicationName: "", dose: "", frequency: [], duration: [] },
    ]);


    const [rightSlitlamp, setRightSlitlamp] = useState();
    const [rightFundus, setRightFundus] = useState();
    const [diagnose, setDiagnose] = useState("");

    const [advise, setAdvise] = useState();
    const [nextFollowupDate, setNextFollowupDate] = useState();

    const handleChangeRightSlitLamp = (e) => {
        let slectedValue = e.target.value
        setRightSlitlamp(slectedValue);
    }
    const handleChangeRightFundus = (e) => {
        let slectedValue = e.target.value
        setRightFundus(slectedValue);
    }
    const handleChangeDiagnose = (e) => {
        let slectedValue = e.target.value
        setDiagnose(slectedValue);
    }

    const handleChangeAdvise = (e) => {
        let slectedValue = e.target.value
        setAdvise(slectedValue);
    }
    const handleChangeNextFollowupDate = (e) => {
        let slectedValue = e.target.value
        setNextFollowupDate(slectedValue);
    }

    // Function to handle changes in input fields
    const handleMedicationChange = (index, event) => {
        const values = [...medications];
        if (event.target.name === "frequency" || event.target.name === "duration") {
            values[index][event.target.name] = [event.target.value];
        } else {

            values[index][event.target.name] = event.target.value;
        }
        setMedications(values);
    };

    // Function to add a new medication field
    const handleAddMedication = () => {
        setMedications([...medications, { medicationName: "", dose: "", duration: [], frequency: [] }]);
    };

    // Function to remove a medication field
    const handleRemoveMedication = (index) => {
        const values = [...medications];
        values.splice(index, 1);
        setMedications(values);
    };

    const handleAddTaperDose = (index) => {
        const updatedMedications = [...medications];

        // Initialize frequency and duration as arrays if not already
        if (!Array.isArray(updatedMedications[index].frequency)) {
            updatedMedications[index].frequency = [updatedMedications[index].frequency];
            updatedMedications[index].duration = [updatedMedications[index].duration];
        }

        // Add new frequency and duration inputs
        updatedMedications[index].frequency.push("");
        updatedMedications[index].duration.push("");

        setMedications(updatedMedications);
    };
    const handleRemoveTaperDose = (medIndex, freqIndex) => {
        const updatedMedications = [...medications];

        updatedMedications[medIndex].frequency.splice(freqIndex, 1);
        updatedMedications[medIndex].duration.splice(freqIndex, 1);

        setMedications(updatedMedications);
    };

    const handleDurationChange = (medIndex, durIndex, event) => {
        const updatedMedications = [...medications];
        updatedMedications[medIndex].duration[durIndex] = event.target.value;
        setMedications(updatedMedications);
    };

    const handleTaperDoseChange = (medIndex, durIndex, event) => {
        const updatedMedications = [...medications];
        updatedMedications[medIndex].frequency[durIndex] = event.target.value;
        setMedications(updatedMedications);
    };




    // ==================================glasses check box==========================
    const [selectedItemsCheckBox, setSelectedItemsCheckBox] = useState(["Medicine"]);
    const [selectedItemsCheckBoxError, setSelectedItemsCheckBoxError] = useState("");

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedItemsCheckBox((prev) => [...prev, value]); // Add to selected items
            setSelectedItemsCheckBoxError("");

        } else {
            setSelectedItemsCheckBox((prev) => prev.filter((item) => item !== value)); // Remove from selected items
            setSelectedItemsCheckBoxError("");

        }

    };
    //   ====================================end glasses check box==========================
    const handleSubmit = async (event) => {
        // event.preventDefault();
        setLoading(true)
        setNetworkError("")
        try {
            const body = {
                patientId: selectedPatient.patientId._id,
                optometristId: selectedPatient._id,
                slitlamp: rightSlitlamp,
                fundus: rightFundus,
                advise: advise,
                diagnose: diagnose,
                nextFollowupDate: nextFollowupDate,
                medications: medications,
                adviceDetails: selectedItemsCheckBox
            };
            const token = localStorage.getItem("beenayi_token");
            const headers = { Authorization: `Bearer ${token}` };
            const response = await fetch("/doctor/prescription", "post", body, headers);
            if (response.data.success === false) {
                setLoading(false);
                setNetworkError(response.data.message);
            } else {
                setLoading(false);
                setSuccessMessage(response.data.message);
                toast.success("Successfully Added An Entry!");

                const body = {
                    patientId: selectedPatient.patientId._id,
                    optometristId: selectedPatient._id,
                    rightSlitLamp: rightSlitlamp,
                    rightFundus: rightFundus,
                    medications: medications,
                    advise: advise,
                    diagnose: diagnose,
                    nextFollowupDate: nextFollowupDate,
                    adviceDetails: selectedItemsCheckBox

                };
                const PrescriptionContent = `
                <html lang="en">
                  <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <style>
                      body {
                        font-family: Arial, sans-serif;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                      }
               
                      .container {
                        padding: 20px;
                        margin: 0 auto;
                        width: 90%;
                        max-width: 900px;
                      }
               
                      h1, h2 {
                        text-align: center;
                        margin-bottom: 10px;
                      }
               
                      .header, .footer, .body-section {
                        width: 100%;
                        margin-bottom: 15px;
                      }
               
                      .header table, .body-section table {
                        width: 100%;
                        border-collapse: collapse;
                        font-size: 14px;
                        margin-top: 10px;
                      }
               
                      table th, table td {
                        padding: 8px;
                        border: 1px solid #ddd;
                        text-align: left;
                      }
               
                      .section-title {
                        font-weight: bold;
                        text-decoration: underline;
                        margin-bottom: 5px;
                        font-size: 14px;
                      }
               
                      .details {
                        display: flex;
                        justify-content: space-between;
                        font-size: 14px;
                      }
               
                      .divider {
                        border: none;
                        border-top: 1px solid #000;
                        margin: 10px 0;
                      }
               
                      .advice, .diagnosis {
                        font-weight: bold;
                        margin-top: 15px;
                      }
                    </style>
                  </head>
                  <body style="margin-top:255px; margin-bottom:70px">
                    <div style="margin-left:70px; margin-right:50px">
                      <!-- Header Section -->
                      <div class="header">
                        <div class="details">
                          <div>
                            <p style="margin-top:0px; margin-bottom:4px"><strong>Patient Name:</strong>${selectedPatient?.patientId?.fName}</p>
                            <p style="margin-top:4px; margin-bottom:4px"><strong>Age/Sex:</strong> ${selectedPatient?.patientId?.age} years / ${selectedPatient?.patientId?.gender}</p>
                             <p style="margin-top:0px; margin-bottom:4px"><strong>Systemic History:</strong> ${selectedPatient?.patientId?.systemicHistory || "N/A"}</p>
                          </div>
                          <div>
                           <p style="margin-top:4px; margin-bottom:0px"><strong>Contact:</strong> ${selectedPatient?.patientId?.contact}</p>
                            <p style="margin-top:4px; margin-bottom:0px"><strong>Date:</strong>${formatDated(selectedPatient?.patientId?.updatedAt)}</p>
                           
                          </div>
                        </div>
                      </div>
               
                      <!-- Body Section -->
                      <div class="body-section">
                        <!-- History Section -->
                        <!--<h3 class="section-title">History</h3> -->
                        <div class="details">
                           <p style="margin-top:4px; margin-bottom:0px">Right Eye Vision:
                            ${selectedPatient?.rightEyeVision?.unaided === "-"? "":` unAided: ${selectedPatient?.rightEyeVision?.unaided}`},
                              
                               ${selectedPatient?.rightEyeVision?.withGlasses === "-"? "":` c̅ ${selectedPatient?.rightEyeVision?.withGlasses}`}
                           </p>
                            <p style="margin-top:4px; margin-bottom:0px">Left Eye Vision:
                             ${selectedPatient?.leftEyeVision?.unaided === "-"? "":` unAided: ${selectedPatient?.leftEyeVision?.unaided}`},
                                 ${selectedPatient?.leftEyeVision?.withGlasses === "-"? "":` c̅ ${selectedPatient?.leftEyeVision?.withGlasses}`}
                           </p>
                           <p style="margin-top:4px; margin-bottom:0px">Vision Type: ${selectedPatient?.visionType}</p>
                          </div>
                        <div style="margin-top:-12px;">
                          <p><strong>Chief Complaints:</strong> ${selectedPatient?.patientComplaint?.map((cmp, index) => `<span>
                                   ${cmp},
                               </span>
                               ` )?.join("")}
                              <span style="padding-left:6px">${selectedPatient?.eyeComplaint}</span>
                           </p>
                        </div>
                         <div style="margin-top:-12px;">
                               <p style="margin-top:0px; margin-bottom:4px"><strong>Systemic History:</strong> ${selectedPatient?.patientId?.systemicHistory || "N/A"}</p>
                               </div>
               
                        <!-- Glasses Prescription -->
                      <div style="display: flex; flex-direction: row; gap: 10px;">
                       ${glassesClose === true ? "" : (

                        `<div style="width: 49%;">
                            <h3 class="section-title">Glasses Prescriptions</h3>
                            <table style="border-collapse: collapse; width: 100%;">
                                <tr>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Eye</th>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Sph</th>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;text-align: left; font-size:12px">Cyl</th>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Axis</th>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Vision</th>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Right Eye</strong></td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.rightEyeVision?.sph || "N/A"}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.rightEyeVision?.cyl || "N/A"}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.rightEyeVision?.axis || "N/A"}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.rightEyeVision?.visionResult || "N/A"}</td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Left Eye</strong></td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.leftEyeVision?.sph}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.leftEyeVision?.cyl}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.leftEyeVision?.axis}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.leftEyeVision?.visionResult}</td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Near Vision (Both Eyes)</strong></td>
                                    <td colspan="3" style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: center;">${selectedPatient?.leftEyeVision?.nearVision}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: center;">${selectedPatient?.leftEyeVision?.nearVisionResult}</td>
                                </tr>
                            </table>
                        </div>`
                    )

                    }
                    
        
                  
          ${ophthalmicClose === true ? "" : (
                        ` <div style="width: 49%;">
                            <h3 class="section-title">Ophthalmic Findings</h3>
                            <table style="border-collapse: collapse; width: 100%;">
                                <tr>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Eye</th>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">IOP</th>
                                    <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Syringing</th>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Right Eye</strong></td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.eyePressure?.right}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.syringDone?.right}</td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Left Eye</strong></td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.eyePressure?.left}</td>
                                    <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPatient?.syringDone?.left}</td>
                                </tr>
                            </table>
                        </div>`
                    )}
                </div>
               
                        <!-- Examination Section -->
               <div style="display: flex; flex-direction: row; gap: 10px;">
               ${slitLampClose === true ? "" : (
                        `<div style="width: 49%;">
                        <h3 class="section-title">Slit Lamp Examination</h3>
                        <table>
                          <tr>
                            <th style="width: 20%; padding-top: 2px; padding-bottom: 0px; font-size:12px">Findings</th>
                            <th style="width: 80%; padding-top: 2px; padding-bottom: 0px; font-size:12px">${body?.rightSlitLamp || "N/A"}</th>
                          </tr>
                          
                         
                        </table>
                    </div>`
                    )}
                     ${fundusClose === true ? "" : (
                        ` <div style="width: 49%;">
                        <h3 class="section-title font-size:12px">Fundus Examination</h3>
                        <table>
                          <tr>
                            <th style="width: 20%; padding-top: 2px; padding-bottom: 0px; font-size:12px">Findings</th>
                            <th style="width: 80%; padding-top: 2px; padding-bottom: 0px; font-size:12px">${body?.rightFundus || "N/A"}</th>
                          </tr>
                         
                         
                        </table>
                    </div>`
                    )}
                </div>
               
                        <!-- Diagnosis Section -->
                         ${diangnoseClose === true ? "" : (
                        `<h3 class="section-title">Diagnosis</h3>
                        <p style="margin-top:-4px">${body?.diagnose || "N/A"}</p>
                     
                      <hr class="divider">`
                    )}
                <!-- Medicine -->
                <div style="width: 100%;">
                    <h3 class="section-title">Medicine</h3>
                    <table style="border-collapse: collapse; width: 100%;">
                      <tr>
                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Medication Name</th>
                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Dose</th>
                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Frequency</th>
                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Duration</th>
                      </tr>
                      <tbody>
                      ${body?.medications?.map((item, index) => `
                               <tr key=${index}>
                                   <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.medicationName}</td>
                                   <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.dose}</td>
                                   
                                   <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.frequency?.join('<br>')}</td>
                                   <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.duration?.join('<br>')}</td>
                               </tr>
                           
                           `)?.join("")}
                      </tbody>
                    </table>
               </div>
               
                           ${adviseClose === true ? "" : (
                        `<div class="advice">
                        <p><strong>Advice:</strong>${body?.advise || "N/A"}</p>
                      </div>`
                    )}
                       ${followUpClose === true ? "" : (
                        `<hr class="divider">
                       <div class="advice">
                        <p><strong>Next Follow-up:</strong> After ${body?.nextFollowupDate || "SOS"}</p>
                      </div>`
                    )}
                    </div>
                  </body>
                </html>`;
                const iframe = document.createElement("iframe");
                document.body.appendChild(iframe);

                const style = `
                    <style>
                        @page {
                            margin: 0; /* Removes margins that typically include title, URL, or date */
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    </style>
                `;

                const content = `
                ${style}
                ${PrescriptionContent}
            `;

                iframe.contentWindow.document.open();
                iframe.contentWindow.document.write(content);
                iframe.contentWindow.document.close();
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
                iframe.remove();

                getPtDetails();
                resetfield();

                setSelectedItemsCheckBox(["Medicine"]);
                setGlassesClose(false);
                setOphthalmicClose(false);

                setAddPatientMedicineOpen(false);
                setRightSlitlamp("");
                setRightFundus("");
                setAdvise("");
                setNextFollowupDate("");
                setMedications([
                    { medicationName: "", dose: "", frequency: [], duration: [] },
                ]);

            }

        } catch (err) {
            setLoading(false)
            // setNetworkError(err)
            console.log(err);
        }
    };

    // const printingHandler = (body) => {

    // };
    const resetfield = () => {
        setGlassesClose(false)
        setOphthalmicClose(false)
        setSlitLampClose(false)
        setFundusClose(false)
        setAdviselose(false)
        setFollowUpClose(false)
        setDiangnoseClose(false)

        setSelectedItemsCheckBox(["Medicine"]);
        setRightSlitlamp("");
        setRightFundus("");
        setAdvise("");
        setNextFollowupDate("");
        setNetworkError("")
        setDiagnose("")
        setMedications([
            { medicationName: "", dose: "", frequency: [], duration: [] },
        ]);

    };

    return (
        <Transition.Root show={addPatientMedicineOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setAddPatientMedicineOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex mt-10 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="max-[400px]:w-[100%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[90%] sm:w-[90%] md:w-[90%] xl:w-[80%]">
                                <div className="flex flex-row justify-between items-center">
                                    <div className=" px-4 text-start  bg-white">
                                        <h2 className="text-gray-600 text-lg font-semibold    ">
                                            Add Prescription
                                        </h2>
                                    </div>
                                    <div className="px-4 py-3 sm:px-6  ">
                                        <button
                                            type="button"
                                            className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900 focus:outline-none"
                                            onClick={() => {
                                                setAddPatientMedicineOpen(false);
                                                resetfield();
                                            }}
                                            ref={cancelButtonRef}
                                        >
                                            <XMarkIcon className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                                <div className="ml-[3%] mb-6">
                                    <div className=" w-[97%] bg-gray-50 px-2 py-3 rounded-lg">

                                        <div className="overflow-x-auto">
                                            <table className="min-w-full table-auto border-collapse border border-[#0d5781]  text-sm">
                                                <tbody>
                                                    <React.Fragment>
                                                        <tr className="border border-[#0d5781] ">
                                                            <td className="px-2 py-2 font-bold w-[7%] border-[1px] whitespace-nowrap">Name</td>
                                                            <td className="px-2 py-2 font-normal w-[40%] border-[1px] whitespace-nowrap">{selectedPatient?.patientId?.fName}{" "}{selectedPatient?.patientId?.lName}</td>
                                                            <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Age/Gender:</td>
                                                            <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{selectedPatient?.patientId?.age}/ {selectedPatient?.patientId?.gender}</td>
                                                        </tr>
                                                        <tr className="border border-[#0d5781] ">
                                                            <td className="px-2 py-2 font-bold w-[10%] border-[1px] whitespace-nowrap">Contact</td>
                                                            <td className="px-2 py-2 font-normal  w-[10%] border-[1px] whitespace-nowrap">{selectedPatient?.patientId?.contact}</td>
                                                            <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Date & Time</td>
                                                            <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{formatDated(selectedPatient?.updatedAt)}</td>

                                                        </tr>

                                                    </React.Fragment>
                                                    {/* );
                                                        })} */}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* Optometrist details */}
                                        <div className="overflow-x-auto pt-4">
                                            <table className="min-w-full table-auto border-collapse border border-[#0d5781]  text-sm">
                                                <tbody>
                                                    <tr className="border border-[#0d5781] ">
                                                        <td className="px-2 py-2 font-bold w-[7%] border-[1px] whitespace-nowrap">Right Eye Vision</td>
                                                        <td className="px-2 py-2 font-normal w-[40%] border-[1px] whitespace-nowrap">{selectedPatient?.rightEyeVision?.unaided ==="-"? null:(
                                                            <>
                                                            unAided: {" "}
                                                            {selectedPatient?.rightEyeVision?.unaided}
                                                            </>
                                                        )},
                                                        {selectedPatient?.rightEyeVision?.withGlasses === "-" ?null:(<>
                                                            c̅{" "}
                                                        {selectedPatient?.rightEyeVision?.withGlasses}
                                                        </>)}
                                                     
                                                        </td>
                                                        <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Left Eye Vision</td>
                                                        <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{selectedPatient?.leftEyeVision?.unaided ==="-"? null:(
                                                            <>
                                                            unAided: {" "}
                                                            {selectedPatient?.leftEyeVision?.unaided}
                                                            </>
                                                        )},
                                                        {selectedPatient?.leftEyeVision?.withGlasses === "-" ?null:(<>
                                                            c̅ {" "}
                                                        {selectedPatient?.leftEyeVision?.withGlasses}
                                                        </>)}
                                                        </td>
                                                        <td className="px-2 py-2 font-bold w-[10%] border-[1px] whitespace-nowrap">Vision Type</td>
                                                        <td className="px-2 py-2 font-normal  w-[10%] border-[1px] whitespace-nowrap">{selectedPatient?.visionType}</td>

                                                    </tr>
                                                    <tr className="border border-[#0d5781] ">
                                                        <td className="px-2 py-2 font-bold w-[7%] border-[1px] whitespace-nowrap">Complaints</td>
                                                        <td className="p-2 font-normal w-[40%] border-[1px]" colSpan={5}>{selectedPatient?.patientComplaint?.join(", ")
                                                        }  <span className="font-semibold px-2">{selectedPatient?.eyeComplaint}</span> </td>
                                                    </tr>
                                                    {selectedPatient?.patientId?.systemicHistory === undefined || null ? null: (
                                                    <tr className="border border-[#0d5781] ">
                                                        <td className="px-2 py-2 font-bold w-[20%] border-[1px] whitespace-nowrap">Systemic History</td>
                                                        <td className="p-2 font-normal w-[800%] border-[1px]" colSpan={5}>{selectedPatient?.patientId?.systemicHistory}</td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    {/* Glasses and opthalmic finding */}
                                    <div className="container mx-auto">
                                        <div className="flex flex-col md:flex-row gap-4 px-6 py-3">
                                            <XMarkIcon className="w-6 h-6 border-[1px] p-[1px] text-red-600" onClick={() => {
                                                if (glassesClose === false) {
                                                    setGlassesClose(true)
                                                } else (
                                                    setGlassesClose(false)
                                                )
                                            }} />
                                            {!glassesClose && (

                                                <div className={`${ophthalmicClose === true ? 'w-full' : 'w-1/2'}`}>
                                                    <h3 className="text-lg font-semibold">Glasses</h3>
                                                    <table className="w-full border-collapse">
                                                        <thead>
                                                            <tr>
                                                                <th className="border border-black px-2 py-1 text-left">Eye</th>
                                                                <th className="border border-black px-2 py-1 text-center">Sph</th>
                                                                <th className="border border-black px-2 py-1 text-center">Cyl</th>
                                                                <th className="border border-black px-2 py-1 text-center">Axis</th>
                                                                <th className="border border-black px-2 py-1 text-center">Vision</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="border border-black px-2 py-1 font-semibold">Right Eye</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.rightEyeVision?.sph}</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.rightEyeVision?.cyl}</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.rightEyeVision?.axis}</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.rightEyeVision?.visionResult}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border border-black px-2 py-1 font-semibold">Left Eye</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.leftEyeVision?.sph}</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.leftEyeVision?.cyl}</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.leftEyeVision?.axis}</td>
                                                                <td className="border border-black px-2 py-1 text-center">{selectedPatient?.leftEyeVision?.visionResult}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border border-black px-1 py-1 font-semibold">Near Vision (Both)</td>
                                                                <td colSpan="3" className="border border-black px-2 py-1 text-center">{selectedPatient?.rightEyeVision?.nearVision}</td>
                                                                <td className="border border-black px-2 py-1">{selectedPatient?.rightEyeVision?.nearVisionResult}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {/* Ophthalmic Findings Table */}
                                            {!ophthalmicClose && (

                                                <div className={`${glassesClose === true ? 'w-full' : 'w-1/2'}`}>
                                                    <h3 className="text-lg font-semibold">Ophthalmic Findings</h3>
                                                    <table className="w-full border-collapse">
                                                        <thead>
                                                            <tr>
                                                                <th className="border border-black p-2 text-left w-1/3">Eye</th>
                                                                <th className="border border-black p-2 text-left w-1/3">IOP</th>
                                                                <th className="border border-black p-2 text-left w-[40%]">Syringing</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="border border-black p-1 font-semibold">Right Eye</td>
                                                                <td className="border border-black p-1">{selectedPatient?.eyePressure?.right} mmHg</td>
                                                                <td className="border border-black p-1 max-w-[150px] break-words whitespace-normal">
                                                                    {selectedPatient?.syringDone?.right}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border border-black p-1 font-semibold">Left Eye</td>
                                                                <td className="border border-black p-1">{selectedPatient?.eyePressure?.left} mmHg</td>
                                                                <td className="border border-black p-1 max-w-[150px] break-words whitespace-normal">{selectedPatient?.syringDone?.left}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            <XMarkIcon className="w-6 h-6 border-[1px] p-[1px] text-red-600" onClick={() => {
                                                if (ophthalmicClose === false) {
                                                    setOphthalmicClose(true)
                                                } else (
                                                    setOphthalmicClose(false)
                                                )
                                            }} />
                                        </div>


                                        <h1 className="w-[97%] block text-base font-medium text-center leading-20 text-gray-900 my-4">
                                            -- Examination Here --
                                        </h1>
                                        {/* <div className="px-8 grid grid-cols-1 sm:grid-cols-2 gap-4"> */}
                                        <div className="px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">

                                            <div>
                                                <div className="flex items-center justify-between flex-row-reverse">
                                                    <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                                                        onClick={() => {
                                                            if (slitLampClose === false) {
                                                                setSlitLampClose(true)
                                                            } else (
                                                                setSlitLampClose(false)
                                                            )
                                                        }} />
                                                </div>
                                                {!slitLampClose && (
                                                    <>
                                                        <h6 className="text-xl ">Slit Lamp Examination</h6>
                                                        <textarea
                                                            className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                            rows={2}
                                                            value={rightSlitlamp}
                                                            placeholder="Add Right Slit Lamp... "
                                                            onChange={handleChangeRightSlitLamp}
                                                        />
                                                    </>)}
                                            </div>

                                            <div>
                                                <div className="flex items-center justify-between flex-row-reverse">
                                                    <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                                                        onClick={() => {
                                                            if (fundusClose === false) {
                                                                setFundusClose(true)
                                                            } else (
                                                                setFundusClose(false)
                                                            )
                                                        }} />
                                                </div>
                                                {!fundusClose && (
                                                    <>
                                                        <h6 className="text-xl ">Fundus Examination</h6>
                                                        <textarea
                                                            className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                            rows={2}
                                                            placeholder="Add Right Fundus... "

                                                            value={rightFundus}
                                                            onChange={handleChangeRightFundus}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <div className="flex items-center justify-between flex-row-reverse">
                                                    <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                                                        onClick={() => {
                                                            if (adviseClose === false) {
                                                                setAdviselose(true)
                                                            } else (
                                                                setAdviselose(false)
                                                            )
                                                        }} />
                                                </div>
                                                {!adviseClose && (
                                                    <>
                                                        <h6 className="text-xl ">Advise Or Test</h6>
                                                        <textarea
                                                            className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"

                                                            rows={1}
                                                            value={advise}
                                                            placeholder="Advise Or Test here... "
                                                            onChange={handleChangeAdvise}
                                                        />
                                                    </>)}

                                            </div>

                                            <div>
                                                <div className="flex items-center justify-between flex-row-reverse">
                                                    <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                                                        onClick={() => {
                                                            if (followUpClose === false) {
                                                                setFollowUpClose(true)
                                                            } else (
                                                                setFollowUpClose(false)
                                                            )
                                                        }} />
                                                </div>
                                                {!followUpClose && (
                                                    <>
                                                        <h6 className="text-xl ">Next Follow Up</h6>

                                                        <textarea
                                                            className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                            placeholder="Next Follow Up Date..."
                                                            rows={1}
                                                            value={nextFollowupDate}
                                                            onChange={handleChangeNextFollowupDate}
                                                        />
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                        <div className="px-8">
                                            <div className="flex items-center justify-between flex-row-reverse">
                                                <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                                                    onClick={() => {
                                                        if (diangnoseClose === false) {
                                                            setDiangnoseClose(true)
                                                        } else (
                                                            setDiangnoseClose(false)
                                                        )
                                                    }} />
                                            </div>
                                            {!diangnoseClose && (
                                                <>
                                                    <h6 className="text-xl ">Diagnose</h6>
                                                    <textarea
                                                        className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                        rows={1}
                                                        placeholder="Add Diagnose... "

                                                        value={diagnose}
                                                        onChange={handleChangeDiagnose}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <h1 className="w-[97%] block text-base font-medium text-center leading-20 text-gray-900 mt-4">
                                            -- Prescribe Meditation Here --
                                        </h1>

                                        <form className="w-full max-w-5xl mx-auto">
                                            {medications.map((medication, index) => (
                                                <div key={index} className="mb-6 bg-gray-50 px-4 rounded-lg shadow-md">
                                                    <div className="flex justify-end mb-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveMedication(index)}
                                                            className="text-red-600 hover:text-red-800"
                                                        >
                                                            __
                                                        </button>
                                                    </div>
                                                    {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"> */}
                                                    <div className="flex items-start justify-between gap-2">

                                                        {/* Medication Name */}
                                                        <div className="mb-3">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Medication Name<span className="text-red-600 font-bold"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="medicationName"
                                                                placeholder="Enter Medi Name"
                                                                value={medication.medicationName}
                                                                onChange={(event) => handleMedicationChange(index, event)}
                                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                required
                                                            />
                                                        </div>

                                                        {/* Dose */}
                                                        <div className="mb-3">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Dose<span className="text-red-600 font-bold"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="dose"
                                                                placeholder="Enter Dose"
                                                                value={medication.dose}
                                                                onChange={(event) => handleMedicationChange(index, event)}
                                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                required
                                                            />
                                                        </div>
                                                        {/* Frequency */}
                                                        {/* <div className="mb-3">
                                                        <label className="block text-sm font-medium text-gray-700">
                                                            Frequency<span className="text-red-600 font-bold"> *</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="frequency"
                                                            placeholder="Enter Frequency"
                                                            value={medication.frequency}
                                                            onChange={(event) => handleMedicationChange(index, event)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            required
                                                        />
                                                    </div> */}
                                                        <div className="mb-3 relative">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Frequency<span className="text-red-600 font-bold"> *</span>
                                                            </label>

                                                            {/* Render multiple frequency inputs for taper doses */}
                                                            {Array.isArray(medication?.frequency) && medication?.frequency?.length > 0 ? (
                                                                medication.frequency.map((freq, freqIndex) => (
                                                                    <div key={freqIndex} className="relative mb-2 flex items-center space-x-4">
                                                                        <input
                                                                            type="text"
                                                                            name={`frequency-${freqIndex}`}
                                                                            placeholder={`Enter Frequency ${freqIndex + 1}`}
                                                                            value={freq}
                                                                            onChange={(event) => handleTaperDoseChange(index, freqIndex, event)}
                                                                            className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            required
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            name={`duration-${freqIndex}`}
                                                                            placeholder={`Enter Duration ${freqIndex + 1}`}
                                                                            value={medication.duration[freqIndex]}
                                                                            onChange={(event) => handleDurationChange(index, freqIndex, event)}
                                                                            className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            required
                                                                        />

                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleRemoveTaperDose(index, freqIndex)}
                                                                            className="absolute right-0 top-10 px-2 py-1 text-black focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                                                        >
                                                                            -
                                                                        </button>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="flex items-center space-x-4">

                                                                    <input
                                                                        type="text"
                                                                        name="frequency"
                                                                        placeholder="Enter Frequency"
                                                                        value={medication.frequency}
                                                                        onChange={(event) => handleMedicationChange(index, event)}
                                                                        className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        required
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        name="duration"
                                                                        placeholder="Enter Duration"
                                                                        value={medication.duration}
                                                                        onChange={(event) => handleMedicationChange(index, event)}
                                                                        className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        required
                                                                    />
                                                                </div>


                                                            )}

                                                            {/* + Button to add taper dose */}
                                                            <button
                                                                type="button"
                                                                onClick={() => handleAddTaperDose(index)}
                                                                className="absolute  right-0 top-0 -mt-2 px-2 py-1 text-black focus:ring-offset-2"
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        {/* Duration */}
                                                        {/* <div className="mb-3">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Duration<span className="text-red-600 font-bold"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="duration"
                                                                placeholder="Enter Duration"
                                                                value={medication.duration}
                                                                onChange={(event) => handleMedicationChange(index, event)}
                                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                required
                                                            />
                                                        </div> */}
                                                    </div>


                                                </div>
                                            ))}

                                            {/* Add Medication Button */}
                                            <div className="mb-4">
                                                <button
                                                    type="button"
                                                    onClick={handleAddMedication}
                                                    className="px-2 py-0 rounded-full bg-[#0d5781] text-white shadow-sm hover:bg-[#0d5781] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                >
                                                    +
                                                </button>
                                            </div>

                                            {/* Submit Button */}
                                            {/* <div className="mb-6">
                                                <button
                                                    // type="submit"
                                                    className="px-4 py-2 bg-[#0d5781] text-white justify-center rounded-md shadow-sm hover:bg-[#0d5781] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                >
                                                    Submit
                                                </button>
                                            </div> */}
                                        </form>
                                    </div>
                                    <div className="container mx-auto w-[90%]">
                                        <div className="w-[100%] gap-y-1 gap-x-0 grid grid-cols-1 max-[640px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5">

                                            {glassesCheckBoxList?.map((item, index) => (
                                                <div className="mt-3" key={index}>
                                                    <div className="flex items-center mb-2">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${index}`}
                                                            value={item}
                                                            checked={selectedItemsCheckBox.includes(item)} // Set checked if item is in selectedItems
                                                            onChange={handleCheckboxChange}
                                                            className="mr-2"
                                                        />
                                                        <label htmlFor={`checkbox-${index}`}>{item}</label>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                        {selectedItemsCheckBoxError && (
                                            <div className="flex ml-[0.5px] text-center">
                                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                                    {selectedItemsCheckBoxError}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center">
                                    {loading && (
                                        <strong className="text-[#0d5781] text-sm  text-center">
                                            {loadingMessage}
                                        </strong>
                                    )}
                                    {networkError && (
                                        <strong className="text-red-600 text-sm  text-center">
                                            {networkError}
                                        </strong>
                                    )}
                                    {successMessage && (
                                        <strong className="text-green-800 text-sm  text-center">
                                            {successMessage}
                                        </strong>
                                    )}
                                </div>
                                <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        disabled={loading}
                                        className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#0d5781] hover:bg-[#0d5781] "
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        {loading === true ?
                                            (
                                                <MoonLoader
                                                    color="#fff"
                                                    loading={loading}
                                                    size={20}
                                                    className="mx-1"
                                                />
                                            ) : (

                                                <>Submit</>
                                            )}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => {
                                            resetfield();
                                            setAddPatientMedicineOpen(false);
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
