// /** @format */

// import React, { useEffect, useRef, useState } from "react";
// import useOutsideClick from "../../hook/outside-click-hook";
// import AdminDatePicker from "../components/AdminDatePicker";
// import { padDate } from "../../service/date.utils";

// const DateWiseSelect = ({ startDate, setStartDate, endDate, setEndDate }) => {
//     const [calendarOpen, setCalendarOpen] = useState(false);

//     useEffect(() => {
//         setStartDate(startDate);
//         setEndDate(endDate);
//     }, []);
//     const today = new Date();
//     const todayDateString = today.toISOString().split('T')[0];
//     const wrapperRef = useRef(null);
//     const datePickerRef = useRef(null);

//     useOutsideClick(wrapperRef, datePickerRef, () => {
//         setCalendarOpen(false);
//     });

//     return (
//         <>
//             <div className="pl-[12px] pr-[55px]  flex items-end justify-end">

//                 <div className="search-penal px-1 text-dark mt-3">
//                     <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
//                         {/* From Date */}
//                         <div>
//                             <label htmlFor="from" className="block">
//                                 From Date:
//                             </label>
//                             <input
//                                 type="text"
//                                 id="from"
//                                 value={
//                                     startDate
//                                         ? padDate(
//                                             `${startDate?.getDate()}-${startDate?.getMonth() + 1}-${startDate?.getFullYear()}`
//                                         )
//                                         : null
//                                 }
//                                 className="form-control w-[100%] max-[768px]:w-[100%]"
//                                 onClick={() => setCalendarOpen((prev) => !prev)}
//                             />
//                             <div>
//                                 <AdminDatePicker
//                                     calendarOpen={calendarOpen}
//                                     startDate={startDate}
//                                     setStartDate={setStartDate}
//                                     endDate={endDate}
//                                     setEndDate={setEndDate}
//                                     setCalendarOpen={setCalendarOpen}
//                                     wrapperRef={wrapperRef}
//                                     maxDate={todayDateString} // Limit selection to today or before
//                                 />
//                             </div>
//                         </div>

//                         {/* To Date */}
//                         <div>
//                             <label htmlFor="to" className="block">
//                                 To Date:
//                             </label>
//                             <input
//                                 type="text"
//                                 id="to"
//                                 value={
//                                     endDate
//                                         ? padDate(
//                                             `${endDate?.getDate()}-${endDate?.getMonth() + 1}-${endDate?.getFullYear()}`
//                                         )
//                                         : null
//                                 }
//                                 className="form-control w-[100%] max-[768px]:w-[100%]"
//                                 onClick={() => setCalendarOpen((prev) => !prev)}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default DateWiseSelect;
/** @format */

import React, { useEffect, useState } from "react";

const DateWiseSelect = ({ startDate, setStartDate, endDate, setEndDate }) => {
    const [fromDate, setFromDate] = useState(""); // Store the selected "From" date
    const [toDate, setToDate] = useState(""); // Store the selected "To" date
    const today = new Date().toISOString().split("T")[0]; // Today's date in YYYY-MM-DD format

    useEffect(() => {
        // Set initial dates to today if not provided
        if (!startDate) {
            const todayDate = new Date().toISOString().split("T")[0];
            setFromDate(todayDate);
            setStartDate(todayDate);
        } else {
            setFromDate(startDate);
        }

        if (!endDate) {
            const todayDate = new Date().toISOString().split("T")[0];
            setToDate(todayDate);
            setEndDate(todayDate);
        } else {
            setToDate(endDate);
        }
    }, [startDate, setStartDate, endDate, setEndDate]);

    const handleFromDateChange = (e) => {
        const selectedDate = e.target.value; // Get selected date
        setFromDate(selectedDate); // Update "From" date state
        setStartDate(selectedDate); // Update parent state if needed

        // Ensure "To Date" is at least equal to "From Date"
        if (toDate && new Date(selectedDate) > new Date(toDate)) {
            setToDate(selectedDate);
            setEndDate(selectedDate);
        }
    };

    // Handle "To Date" selection
    const handleToDateChange = (e) => {
        const selectedDate = e.target.value; // Get selected date
        setToDate(selectedDate); // Update "To" date state
        setEndDate(selectedDate); // Update parent state if needed

        // Ensure "To Date" is not earlier than "From Date"
        if (fromDate && new Date(selectedDate) < new Date(fromDate)) {
            setFromDate(selectedDate);
            setStartDate(selectedDate);
        }
    };

    return (
        <div className="pl-[12px] pr-[55px]  flex items-end justify-end">
            <div className="search-penal px-1 text-dark mt-3">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                    {/* From Date */}
                    <div>
                        <label htmlFor="from" className="block">
                            From Date:
                        </label>
                        <input
                            type="date"
                            id="from"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            max={today} // Restrict future dates
                            className="form-control w-[100%] max-[768px]:w-[100%]"
                        />
                    </div>

                    {/* To Date */}
                    <div>
                        <label htmlFor="to" className="block">
                            To Date:
                        </label>
                        <input
                            type="date"
                            id="to"
                            value={toDate}
                            onChange={handleToDateChange}
                            min={fromDate} // Ensure "To Date" >= "From Date"
                            max={today} // Restrict future dates
                            className="form-control w-[100%] max-[768px]:w-[100%]"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateWiseSelect;

