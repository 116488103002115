/** @format */

import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import RemoveAdmin from "../middleware/remove-admin.middleware";
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../../redux/features/userSlice";
import { api_admin_url, fetch } from "../../service/utils";

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const verifyToken = async (token) => {
    try {
      setIsLoading(true);
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(`${api_admin_url}/token-info`, "post", null, headers);
      setIsLoading(false);
      if (!response.data.ok) {
        setIsLoading(false);
        dispatch(clearUser());
        localStorage.removeItem("beenayi_token");
        return;
      } else {
        setIsLoading(false);
        const { tokenData } = response.data;
        const userInfo = tokenData;
        dispatch(setUser(userInfo));
      }
    } catch (err) {
      setIsLoading(false);
      localStorage.removeItem("beenayi_token");
      dispatch(clearUser());
    }
  };
  const token = localStorage.getItem("beenayi_token");

  useEffect(() => {
    const token = localStorage.getItem("beenayi_token");
    if (!token) {
      setIsLoading(false);
      localStorage.removeItem("beenayi_token");
      dispatch(clearUser());
    } else {
      verifyToken(token);
    }
  }, [token]);

  return (
    // <RemoveAdmin>
      <div>
        <Header />
        {children}
        <Footer />
      </div>
    // </RemoveAdmin>
  );
};

export default Layout;
