import { createSlice } from "@reduxjs/toolkit";

const PatientDoneByDoctorSlice = createSlice({
  name: "PatientDoneByDoctor",
  initialState: {
    patientSuccessData: [],
  },
  reducers: {
    setPatientSuccessData(state, action) {
      state.patientSuccessData = action.payload;
    },
   
  },
});

export const { setPatientSuccessData} = PatientDoneByDoctorSlice.actions;
export default PatientDoneByDoctorSlice.reducer;
