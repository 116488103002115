/** @format */

import sha1 from "js-sha1";
import axios from "axios";
import { APP_KEY } from "./constant";
import moment from "moment/moment";
//
export const api_admin_url = `https://api.beenayi.com/api/admin`;
// export const api_admin_url = `http://localhost:4000/api/admin`;


export const fetch = async (
  // endPoint = "https://api.beenayi.com/api",
  endPoint = "",
  method = "get", //bydefault get leta h
  data = null, //body
  headers = {}
) => {
  const instance = axios.create({
    baseURL: "https://api.beenayi.com/api",
    // baseURL: "http://localhost:4000/api",
  });
  return await instance({
    url: endPoint,
    method,
    data,
    headers,
  });
};

export const fetchWeb = async (
  // endPoint = "https://api.beenayi.com/api",
  endPoint = "",
  method = "get",
  data = null,
  headers = {}
) => {
  const instance = axios.create({
    baseURL: "https://api.beenayi.com/api/web",
    // baseURL: "http://localhost:4000/api/web",

  });
  return await instance({
    url: endPoint,
    method,
    data,
    headers,
  });
};

export const formatText = (text) => {
  const words = text.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const formattedText = capitalizedWords.join(" ");
  return formattedText;
};

export const formatArray = (arr) => {
  const words = arr && arr.join(",");
  return words;
};

// export const scrollToTop = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };

export const scrollToTop = (top = 0) => {
  window.scrollTo({
    top: top,
    behavior: "smooth",
  });
};

export const formatDate = (date) => {
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  return currentDate;
};

export function formatStringToDate(dateString) {
  const inputDate = new Date(dateString);
  const formattedDate = inputDate.toISOString().slice(0, 11).replace("T", " ");
  return formattedDate;
}

export function checkOutFormatDate(dateStr) {
  const [day, month, year] = dateStr.split("-");
  const date = new Date(year, month - 1, day);
  const formattedDay = date.toLocaleDateString("en-US", { weekday: "short" });
  return `${formattedDay}, ${day}-${month}-${year}`;
}

export const formatRatesCheckOuts = (rates) => {
  const formattedRates = [];

  Object.entries(rates).forEach(([year, dateRates]) => {
    Object.entries(dateRates).forEach(([date, rates]) => {
      const [day, month] = date.split("-");
      const formattedDate = new Date(year, month - 1, day);
      const formattedDay = formattedDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedDateStr = `${formattedDay} ${day}-${month}-${year}`;
      formattedRates.push({
        day: formattedDay,
        date: formattedDateStr,
        price: rates.totalPrice,
      });
    });
  });

  return formattedRates;
};
// export let formatDiscDates = (date) => {
//   const [day, month, year] = date.split("-").map(Number);
//   const monthStr = String(month).padStart(2, "0");
//   const dateStr = `${year}-${monthStr}-${day}`;
//   return dateStr;
// };

export let formatDiscDates = (date) => {
  const [day, month, year] = date.split("-").map(Number);
  const monthStr = String(month).padStart(2, "0");
  const dayStr = String(day).padStart(2, "0");
  const dateStr = `${year}-${monthStr}-${dayStr}`;
  return dateStr;
};
export const formatCheckoutRates = (rates, discount = {}) => {
  const formattedRates = [];
  let discDates = discount ? discount?.disc_dates : null;

  Object.entries(rates).forEach(([year, dateRates]) => {
    Object.entries(dateRates).forEach(([date, rates]) => {
      const [day, month] = date.split("-");
      const formattedDate = new Date(year, month - 1, day);
      const formattedDay = formattedDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const formattedDateStr = `${day}-${month}-${year}`;
      let discountApplied =
        discDates && discDates.includes(padDate(`${year}-${month}-${day}`));

      if (discountApplied) {
        formattedRates.push({
          day: formattedDay,
          date: formattedDateStr,
          price: rates.totalPrice,
          disc_percentage: discount.disc_percentage,
          // disc_amount: (rates.price * discount.disc_percentage) / 100,
        });
      } else {
        formattedRates.push({
          day: formattedDay,
          date: formattedDateStr,
          price: rates.totalPrice,
        });
      }
    });
  });
  return formattedRates;
};

export function padDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const hashUrl = (value) => {
  let hased = sha1(`${value}${APP_KEY}`);
  return hased;
};

export const DMYFormat = (date) => {
  return date.split("-").reverse().join("-");
};

export const resetBookingInLocalStorage = () => {
  let from = new Date().toISOString().split("T")[0];
  let to = new Date(new Date().setDate(new Date().getDate() + 3))
    .toISOString()
    .split("T")[0];
  let expiry = new Date().getTime() + 600000;
  let bookingObj = {
    bookingEncrypt: `from_date=${from}&to_date=${to}&room=1&r1=1:0`,
    bookinginfo: {
      from: from,
      to: to,
      room: [{ adults: 1, extra: 0 }],
    },
    expiry,
  };
  localStorage.setItem("bookingUrl", JSON.stringify(bookingObj));
};

export const getDayNumbersForDateRange = (start, end) => {
  let current = moment(start);
  const endMoment = moment(end);
  const dayNumbers = [];

  while (current.isBefore(endMoment)) {
    // In JavaScript, day() returns 0 for Sunday, 1 for Monday, and so on.
    // We adjust it to match your database, where 1 is Monday and 7 is Sunday.
    const dayNumber = current.day() === 0 ? 7 : current.day();
    dayNumbers.push(dayNumber);
    current.add(1, "days");
  }

  return dayNumbers;
};

export const getGst = (price, gstObj) => {
  let gst;
  if (parseFloat(price) < 1000) {
    gst = gstObj.first;
  } else if (parseFloat(price) >= 1000 && parseFloat(price) <= 2499) {
    gst = gstObj.second;
  } else if (parseFloat(price) >= 2500 && parseFloat(price) <= 7500) {
    gst = gstObj.third;
  } else {
    gst = gstObj.fourth;
  }
  return gst;
};

export function convertDaysToNames(days) {
  const dayMap = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  const dayNumbers = days.split(",");

  const dayNames = dayNumbers.map((day) => dayMap[day]);

  return dayNames.join(", ");
}

export function convertNamesToDays(names) {
  const nameMap = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  // Map each day name to its corresponding number
  const dayNumbers = names.map((name) => nameMap[name]);

  // Join the numbers back into a string
  return dayNumbers.join(",");
}
