import React, { useEffect, useState } from "react";
import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { fetch } from "../../../service/utils";
import EditLoginUserInfo from "../../components/modals/EditLoginUserInfo";
import AddLoginUserInfo from "../../components/modals/AddLoginUserInfo";
import { PropagateLoader } from "react-spinners";
import DeleteModal from "../../../website/components/modals/DeleteModal";
import ToggleSwitch from "../../components/ToggleSwitch.component";
function LoginUserDetails(props) {

  const [addVisionDetail, setAddVisionDetail] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [patientId, setPatientId] = useState();
  const [DeleteRegForm, setDeleteRegForm] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredPatient, setFilteredPatient] = useState([]);
  
  const [editOptoDetail, setEditOptoDetail] = useState({});
  const [optoId, setOptoId] = useState("");

  useEffect(() => {
    setFilteredPatient(userData);
  }, [userData]);


  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase() || "";
    setSearchQuery(query);

    const filtered =
      userData &&
      userData?.length > 0 &&
      userData?.filter((pt) => pt?.fName?.toLowerCase().includes(query) || pt?.phone?.toLowerCase().includes(query) || pt?.email?.toLowerCase().includes(query));
    setFilteredPatient(filtered);
  };

  const getLoginUserDetails = async (e) => {
    setIsLoading(true);
    setNetworkError("");

    try {
      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/admin/get_users", "get", null, headers);
      setIsLoading(false);
      if (response.data.success === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setUserData(response.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again later.";
      setNetworkError(errorMessage);
      toast.error(errorMessage);
      console.log(err);
    }
  };

  useEffect(() => {
    getLoginUserDetails();
  }, []);

  return (
    <>
      <section className="-mx-4 flex flex-wrap p-4">
        <div className="inline-block w-[100%]  border-[1px] border-gray-300 overflow-auto">
          <div className="overflow-auto">
            <div className="flex min-w-[100%]  items-center justify-between py-2 mb-3 bg-[#0d5781]">
              <div className="font-semibold text-lg px-3 text-white ">
                User Details Info
              </div>
              <div className="font-medium text-lg px-2 py-1 rounded-md text-white primery-color mr-9" onClick={() => setAddUserOpen(true)}>
                <PlusCircleIcon className="w-5 h-6 float-left mr-1 mt-[2px]" />  User
              </div>
            </div>
            <div className="flex min-w-full items-center justify-end  mb-2">
              <div className="border-[1px] border-[#0d5781] w-[30%] rounded-[4px] mr-10">
                <input
                  type="text"
                  placeholder="Search Name, Email, Contact..."
                  className="px-2 py-[5px] rounded-[4px] w-full "
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
            {isLoading ? (
              <div className="flex justify-center h-screen items-center">
                <PropagateLoader color="#36d7b7" loading={true} size={15} />
              </div>
            ) : networkError ? (
              <div className="text-red-600 text-center mt-16 font-semibold">
                {networkError}
              </div>
            ) : (
              <div className="overflow-auto">
                <table class="table table-hover">
                  <thead className="bg-[#0d5781] text-white text-[12px]">
                    <tr>
                      <th scope="col" className="w-[3%] text-center font-medium">
                        #
                      </th>
                      <th scope="col" className="w-[14%] text-start font-medium">
                        Full Name
                      </th>

                      <th scope="col" className="w-[12%] text-start font-medium">
                        Email
                      </th>
                      <th scope="col" className="w-[10%] text-start font-medium">
                        Role
                      </th>

                      <th scope="col" className="w-[10%] text-end font-medium">
                        Contact
                      </th>
                      <th scope="col" className="w-[7%] text-center font-medium">
                        City
                      </th>
                     
                      <th scope="col" className="w-[7%] text-center font-medium">
                        Status
                      </th>
                      <th scope="col" className="w-[7%] text-center  font-medium">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPatient &&
                      filteredPatient.length > 0 &&
                      filteredPatient?.map((pt, ind) => {
                        return (
                          <tr key={ind} className="text-[14px]">
                            <td scope="row" className="text-center border-[1px]">
                              {ind + 1}
                            </td>
                            <td className="text-start">
                              {pt?.fName} {pt?.lName}
                            </td>

                            <td className="text-start border-[1px]">
                              {pt?.email}
                            </td>
                            <td className="text-start border-[1px]">
                              {pt?.roleId === 1 ? (
                                <>Admin</>
                              ) : pt?.roleId === 2 ? (
                                <>Developer</>
                              ) : pt?.roleId === 3 ? (
                                <>Optometrist</>
                              ) : pt?.roleId === 4 ? (
                                <>Receptionist</>
                              ) : null}
                            </td>
                            <td className="border-[1px] text-end">
                              {pt?.phone}
                            </td>
                            <td className="text-center border-[1px]">{pt?.city}</td>
                            <td className="text-center border-[1px]">
                              {pt?.roleId === 1 || pt?.roleId === 2 ? null:(
                              <ToggleSwitch
                              id={pt._id}
                              changeStatus={pt.status}
                              url={"/admin/publish-status/"}
                              getData={getLoginUserDetails}
                            />
                              )}
                            </td>
                            <td className="text-center border-[1px]">
                              <div className="flex justify-center items-center space-x-2">
                                <PencilSquareIcon
                                  className="w-4 h-4 ml-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                                  onClick={() => {
                                    setEditOptoDetail(pt);
                                    setAddVisionDetail(true);
                                  }}
                                />
                                 {pt?.roleId === 1 || pt?.roleId === 2 ? null:(
                                <TrashIcon
                                  className="w-4 h-4 mx-2 text-red-500 hover:text-red-800 cursor-pointer"
                                  onClick={() => { setPatientId(pt._id); setDeleteRegForm(true) }}
                                />)}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <EditLoginUserInfo
        addVisionDetail={addVisionDetail}
        setAddVisionDetail={setAddVisionDetail}
        editOptoDetail={editOptoDetail}
        optoDetails={getLoginUserDetails}
        patientId={editOptoDetail?.patientId?._id}
      />
      <AddLoginUserInfo
        addVisionDetail={addUserOpen}
        setAddVisionDetail={setAddUserOpen}
        editOptoDetail={editOptoDetail}
        optoDetails={getLoginUserDetails}
        patientId={editOptoDetail?.patientId?._id}
      />
      <DeleteModal
        openDelete={DeleteRegForm}
        setOpenDelete={setDeleteRegForm}
        id={patientId}
        url={"/admin/delete-user/"}
        getData={getLoginUserDetails}
      />
     
    </>
  );
}

export default LoginUserDetails;
