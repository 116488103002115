import { createSlice } from "@reduxjs/toolkit";

const adminTabDataSlice = createSlice({
  name: "adminTabData",
  initialState: {
    opdFollowup: [],
  },
  reducers: {
    setOpdFollowup(state, action) {
      state.isAuth = true;
      state.opdFollowup = action.payload;
    },
   
  },
});

export const { setOpdFollowup} = adminTabDataSlice.actions;
export default adminTabDataSlice.reducer;
