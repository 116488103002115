
/** @format */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { fetch } from "../../../service/utils";
import { useInput } from "../../../hook/input-hook";
import toast from "react-hot-toast";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Input from "../input/Input.component";
import { useParams } from "react-router-dom";
import { numberReg } from "../../../service/validations/validation";
import { MoonLoader } from "react-spinners";
import { formatDated } from "../../../service/date.utils";
import { glassesCheckBoxList } from "../../../service/constant";


export default function EditPatientMedicine({
    addPatientMedicineOpen, setAddPatientMedicineOpen,
    selectedPatient,
    getPtDetails,
    doctorId
}) {
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [networkError, setNetworkError] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        setNetworkError("");
    }, [addPatientMedicineOpen]);

    const [medications, setMedications] = useState([
        { medicationName: "", dose: "", frequency: [], duration: [] },
    ]);


    const [rightSlitlamp, setRightSlitlamp] = useState();
    const [rightFundus, setRightFundus] = useState();
    const [diagnose, setDiagnose] = useState("");
  const [fundusClose, setFundusClose] = useState(false);
  const [followUpClose, setFollowUpClose] = useState(false);


    const [advise, setAdvise] = useState();
    const [nextFollowupDate, setNextFollowupDate] = useState();


    useEffect(() => {
        setNetworkError("");
        setRightSlitlamp(selectedPatient?.doctor?.slitlamp)
        setRightFundus(selectedPatient?.doctor?.fundus)
        setDiagnose(selectedPatient?.doctor?.diagnose)
        setAdvise(selectedPatient?.doctor?.advise)
        setNextFollowupDate(selectedPatient?.doctor?.nextFollowupDate)
        setMedications(selectedPatient?.doctor?.medications)
        setSelectedItemsCheckBox(selectedPatient?.doctor?.adviceDetails)
    }, [addPatientMedicineOpen,doctorId])

    const handleChangeRightSlitLamp = (e) => {
        let slectedValue = e.target.value
        setRightSlitlamp(slectedValue );
    }
    
    const handleChangeRightFundus = (e) => {
        let slectedValue = e.target.value
        setRightFundus(slectedValue);
    }
    const handleChangeDiagnose = (e) => {
        let slectedValue = e.target.value
        setDiagnose(slectedValue);
    }

    const handleChangeAdvise = (e) => {
        let slectedValue = e.target.value
        setAdvise(slectedValue);
    }
    const handleChangeNextFollowupDate = (e) => {
        let slectedValue = e.target.value
        setNextFollowupDate(slectedValue);
    }

    // Function to handle changes in input fields
    const handleMedicationChange = (index, event) => {
        const values = [...medications];
        if (event.target.name === "frequency" || event.target.name === "duration") {
            values[index][event.target.name] = [event.target.value];
        } else {

            values[index][event.target.name] = event.target.value;
        }
        setMedications(values);
    };

    const handleAddMedication = () => {
        setMedications([...medications, { medicationName: "", dose: "", duration: [], frequency: [] }]);
    };

    // Function to remove a medication field
    const handleRemoveMedication = (index) => {
        const values = [...medications];
        values?.splice(index, 1);
        setMedications(values);
    };

    const handleAddTaperDose = (index) => {
        const updatedMedications = [...medications];

        // Initialize frequency and duration as arrays if not already
        if (!Array?.isArray(updatedMedications[index]?.frequency)) {
            updatedMedications[index].frequency = [updatedMedications[index]?.frequency];
            updatedMedications[index].duration = [updatedMedications[index]?.duration];
        }

        // Add new frequency and duration inputs
        updatedMedications[index]?.frequency?.push("");
        updatedMedications[index]?.duration?.push("");

        setMedications(updatedMedications);
    };
    const handleRemoveTaperDose = (medIndex, freqIndex) => {
        const updatedMedications = [...medications];

        updatedMedications[medIndex]?.frequency?.splice(freqIndex, 1);
        updatedMedications[medIndex]?.duration?.splice(freqIndex, 1);

        setMedications(updatedMedications);
    };

    const handleDurationChange = (medIndex, durIndex, event) => {
        const updatedMedications = [...medications];
        updatedMedications[medIndex].duration[durIndex] = event.target.value;
        setMedications(updatedMedications);
    };

    const handleTaperDoseChange = (medIndex, durIndex, event) => {
        const updatedMedications = [...medications];
        updatedMedications[medIndex].frequency[durIndex] = event.target.value;
        setMedications(updatedMedications);
    };

    
    // ==================================glasses check box==========================
    const [selectedItemsCheckBox, setSelectedItemsCheckBox] = useState(["Medicine"]);
    const [selectedItemsCheckBoxError, setSelectedItemsCheckBoxError] = useState("");

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedItemsCheckBox((prev) => [...prev, value]); // Add to selected items
            setSelectedItemsCheckBoxError("");

        } else {
            setSelectedItemsCheckBox((prev) => prev.filter((item) => item !== value)); // Remove from selected items
            setSelectedItemsCheckBoxError("");

        }

    };
    //   ====================================end glasses check box==========================

      const handleSubmit = async (event) => {
        setLoading(true)
        setNetworkError("")
        try {
           
            const body = {
                doctorId: doctorId,
                slitlamp: rightSlitlamp,
                fundus: rightFundus,
                advise: advise,
                diagnose: diagnose,
                nextFollowupDate: nextFollowupDate,
                medications: medications,
                adviceDetails: selectedItemsCheckBox
            };
            const token = localStorage.getItem("beenayi_token");
            const headers = { Authorization: `Bearer ${token}` };
            const response = await fetch("/doctor/edit_prescription", "put", body, headers);
            if (response.ok === false) {
        setLoading(false);
                setNetworkError(response.data.message);
            } else {
                setLoading(false);
                setSuccessMessage(response.data.message);
                toast.success("Successfully Added An Entry!");
                
                getPtDetails();
                resetfield();
                setSelectedItemsCheckBox(["Medicine"]);
                setAddPatientMedicineOpen(false);
                setRightSlitlamp("");
                setRightFundus("");
                setAdvise("");
                setNextFollowupDate("");
                setMedications([
                    { medicationName: "", dose: "", frequency: [], duration: [] },
                ]);

            }

        } catch (err) {
            console.log(err);
        }
    };
 
    const resetfield = () => {
        setSelectedItemsCheckBox(["Medicine"]);
        setRightSlitlamp("");
        setRightFundus("");
        setSuccessMessage("")
        setAdvise("");
        setNextFollowupDate("");
        setNetworkError("")
        setDiagnose("")
        setMedications([
            { medicationName: "", dose: "", frequency: [], duration: [] },
        ]);

    };
    return (
        <Transition.Root show={addPatientMedicineOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setAddPatientMedicineOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex mt-10 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="max-[400px]:w-[100%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[90%] sm:w-[90%] md:w-[90%] xl:w-[80%]">
                                <div className="flex flex-row justify-between items-center">
                                    <div className=" px-4 text-start  bg-white">
                                        <h2 className="text-gray-600 text-lg font-semibold    ">
                                            Edit Prescription Details
                                        </h2>
                                    </div>
                                    <div className="px-4 py-3 sm:px-6  ">
                                        <button
                                            type="button"
                                            className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900  focus:outline-none  "
                                            onClick={() => {
                                                setAddPatientMedicineOpen(false);
                                                resetfield();
                                            }}
                                            ref={cancelButtonRef}
                                        >
                                            <XMarkIcon className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                                <div className="ml-[3%] mb-6">
                                    <div className=" w-[97%] bg-gray-50 px-2 py-3 rounded-lg">

                                        <div className="overflow-x-auto">
                                            <table className="min-w-full table-auto border-collapse border border-[#0d5781]  text-sm">
                                                <tbody>
                                                    <React.Fragment>
                                                        <tr className="border border-[#0d5781] ">
                                                            <td className="px-2 py-2 font-bold w-[7%] border-[1px] whitespace-nowrap">Name</td>
                                                            <td className="px-2 py-2 font-normal w-[40%] border-[1px] whitespace-nowrap">{selectedPatient?.patient?.fName}</td>
                                                            <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Age/Gender:</td>
                                                            <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{selectedPatient?.patient?.age}/ {selectedPatient?.patient?.gender}</td>


                                                        </tr>
                                                     
                                                        <tr className="border border-[#0d5781] ">
                                                            <td className="px-2 py-2 font-bold w-[10%] border-[1px] whitespace-nowrap">Contact</td>
                                                            <td className="px-2 py-2 font-normal  w-[10%] border-[1px] whitespace-nowrap">{selectedPatient?.patient?.contact}</td>
                                                            <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Date & Time</td>
                                                            <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{formatDated(selectedPatient?.patient?.updatedAt)}</td>

                                                        </tr>

                                                    </React.Fragment>
                                                    {/* );
                                                        })} */}
                                                </tbody>
                                            </table>
                                        </div>

                                       

                                    </div>
                                </div>
                                <div>
                                    {/* Glasses and opthalmic finding */}
                                    <div className="container mx-auto">
                                        
                                       <h1 className="w-[97%] block text-base font-medium text-center leading-20 text-gray-900 my-4">
                                            -- Examination Here --
                                        </h1>
                                        <div className="px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <h6 className="text-xl ">Slit Lamp Examination</h6>
                                                <textarea
                                                    className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                    rows={2}
                                                    value={rightSlitlamp}
                                                    placeholder="Add Right Slit Lamp... "
                                                    onChange={handleChangeRightSlitLamp}
                                                />
                                            </div>
                                            <div>
                                                <h6 className="text-xl ">Fundus Examination</h6>
                                                <textarea
                                                    className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                    rows={2}
                                                    placeholder="Add Right Fundus... "
                                                    value={rightFundus}
                                                    onChange={handleChangeRightFundus}
                                                />
                                            </div>
                                        </div>

                                        <div className="px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <h6 className="text-xl ">Advise Or Test</h6>
                                                <textarea
                                                    className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                    rows={1}
                                                    value={advise}
                                                    placeholder="Advise Or Test here... "
                                                    onChange={handleChangeAdvise}
                                                />
                                            </div>
                                            <div>
                                                <h6 className="text-xl ">Next Follow Up</h6>

                                                <textarea
                                                    className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                    placeholder="Next Follow Up Date..."
                                                    rows={1}
                                                    value={nextFollowupDate}
                                                    onChange={handleChangeNextFollowupDate}
                                                />

                                            </div>
                                        </div>
                                        <div className="px-8">
                                            <h6 className="text-xl ">Diagnose</h6>
                                            <textarea
                                                className="w-full p-2 border rounded-[5px] border-gray-300 focus:outline-none focus:ring-2"
                                                rows={1}
                                                placeholder="Add Diagnose... "

                                                value={diagnose}
                                                onChange={handleChangeDiagnose}
                                            />
                                        </div>
                                        <h1 className="w-[97%] block text-base font-medium text-center leading-20 text-gray-900 mt-4">
                                            -- Prescribe Meditation Here --
                                        </h1>

                                        <form className="w-full max-w-5xl mx-auto">
                                            {medications && medications?.map((medication, index) => (
                                                <div key={index} className="mb-6 bg-gray-50 px-4 rounded-lg shadow-md">
                                                    <div className="flex justify-end mb-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveMedication(index)}
                                                            className="text-red-600 hover:text-red-800"
                                                        >
                                                            __
                                                        </button>
                                                    </div>
                                                    {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"> */}
                                                    <div className="flex items-start justify-between gap-2">

                                                        {/* Medication Name */}
                                                        <div className="mb-3">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Medication Name<span className="text-red-600 font-bold"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="medicationName"
                                                                placeholder="Enter Medi Name"
                                                                value={medication.medicationName}
                                                                onChange={(event) => handleMedicationChange(index, event)}
                                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                required
                                                            />
                                                        </div>

                                                        {/* Dose */}
                                                        <div className="mb-3">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Dose<span className="text-red-600 font-bold"> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="dose"
                                                                placeholder="Enter Dose"
                                                                value={medication.dose}
                                                                onChange={(event) => handleMedicationChange(index, event)}
                                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                required
                                                            />
                                                        </div>
                                                        
                                                        <div className="mb-3 relative">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Frequency<span className="text-red-600 font-bold"> *</span>
                                                            </label>

                                                            {/* Render multiple frequency inputs for taper doses */}
                                                            {Array.isArray(medication?.frequency) && medication?.frequency?.length > 0 ? (
                                                                medication.frequency.map((freq, freqIndex) => (
                                                                    <div key={freqIndex} className="relative mb-2 flex items-center space-x-4">
                                                                        <input
                                                                            type="text"
                                                                            name={`frequency-${freqIndex}`}
                                                                            placeholder={`Enter Frequency ${freqIndex + 1}`}
                                                                            value={freq}
                                                                            onChange={(event) => handleTaperDoseChange(index, freqIndex, event)}
                                                                            className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            required
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            name={`duration-${freqIndex}`}
                                                                            placeholder={`Enter Duration ${freqIndex + 1}`}
                                                                            value={medication.duration[freqIndex]}
                                                                            onChange={(event) => handleDurationChange(index, freqIndex, event)}
                                                                            className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            required
                                                                        />

                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleRemoveTaperDose(index, freqIndex)}
                                                                            className="absolute right-0 top-10 px-2 py-1 text-black focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                                                        >
                                                                            -
                                                                        </button>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="flex items-center space-x-4">

                                                                    <input
                                                                        type="text"
                                                                        name="frequency"
                                                                        placeholder="Enter Frequency"
                                                                        value={medication.frequency}
                                                                        onChange={(event) => handleMedicationChange(index, event)}
                                                                        className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        required
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        name="duration"
                                                                        placeholder="Enter Duration"
                                                                        value={medication.duration}
                                                                        onChange={(event) => handleMedicationChange(index, event)}
                                                                        className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        required
                                                                    />
                                                                </div>


                                                            )}

                                                            {/* + Button to add taper dose */}
                                                            <button
                                                                type="button"
                                                                onClick={() => handleAddTaperDose(index)}
                                                                className="absolute  right-0 top-0 -mt-2 px-2 py-1 text-black focus:ring-offset-2"
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        
                                                    </div>


                                                </div>
                                            ))}

                                            {/* Add Medication Button */}
                                            <div className="mb-4">
                                                <button
                                                    type="button"
                                                    onClick={handleAddMedication}
                                                    className="px-2 py-0 rounded-full bg-[#0d5781] text-white shadow-sm hover:bg-[#0d5781] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                >
                                                    +
                                                </button>
                                            </div>

                                            
                                        </form>
                                    </div>
                                    <div className="container mx-auto w-[90%]">
                                        <div className="w-[100%] gap-y-1 gap-x-0 grid grid-cols-1 max-[640px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5">

                                            {glassesCheckBoxList?.map((item, index) => (
                                                <div className="mt-3" key={index}>
                                                    <div className="flex items-center mb-2">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${index}`}
                                                            value={item}
                                                            checked={selectedItemsCheckBox?.includes(item)} // Set checked if item is in selectedItems
                                                            onChange={handleCheckboxChange}
                                                            className="mr-2"
                                                        />
                                                        <label htmlFor={`checkbox-${index}`}>{item}</label>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                        {selectedItemsCheckBoxError && (
                                            <div className="flex ml-[0.5px] text-center">
                                                <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                                <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                                    {selectedItemsCheckBoxError}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center">
                                    {loading && (
                                        <strong className="text-[#0d5781] text-sm  text-center">
                                            {loadingMessage}
                                        </strong>
                                    )}
                                    {networkError && (
                                        <strong className="text-red-600 text-sm  text-center">
                                            {networkError}
                                        </strong>
                                    )}
                                    {successMessage && (
                                        <strong className="text-green-800 text-sm  text-center">
                                            {successMessage}
                                        </strong>
                                    )}
                                </div>
                                <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        disabled={loading}
                                        className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#0FA75A] hover:bg-[#0d5781] "
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        {loading === true ?
                                            (
                                                <MoonLoader
                                                    color="#fff"
                                                    loading={loading}
                                                    size={20}
                                                    className="mx-1"
                                                />
                                            ) : (

                                                <>Update</>
                                            )}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => {
                                            resetfield();
                                            setAddPatientMedicineOpen(false);
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}