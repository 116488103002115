/** @format */

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import tabDataReducer from "./features/adminTabDataSlice";
import patientDoneByDoctorReducer from "./features/PatientDoneByDoctorSlice"

export default configureStore({
  reducer: {
    user: userReducer,
    tabData:tabDataReducer,
    doctor:patientDoneByDoctorReducer

  },
});
