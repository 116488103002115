import React from 'react'
import { Link } from 'react-router-dom';

function TableBody({ getData }) {
    
    return (
        <tbody className="bg-white divide-y divide-gray-200">
            {getData && getData?.length > 0 ? (
                <>
                    {getData &&
                        getData?.length > 0 &&
                        getData?.map((item, index) => {

                            return (
                                <>

                                    <tr
                                        key={index}
                                        className="hover:text-white hover:bg-slate-100"
                                    >
                                        <td className="px-4 py-2 whitespace-normal text-sm text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            {item.fName}{" "}
                                        </td>
                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            {item.age}
                                        </td>

                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            {item.gender}
                                        </td>
                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            {item.contact}
                                        </td>
                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            {item?.payId === 1 ? (
                                                <>
                                                    <div className="text-green-500">Cash</div>
                                                </>
                                            ) : item.payId === 2 ? (
                                                <>
                                                    <div className="text-orange-500">UPI</div>
                                                </>
                                            ) : item.payId === 3 ? (
                                                <>
                                                    <div className="text-yellow-500">Follow up</div>
                                                </>
                                            ) : item.payId === 4 ? (
                                                <>
                                                    <div className="text-pink-800">Complimentary</div>
                                                </>
                                            ) : null}
                                        </td>
                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            {item.city}
                                        </td>
                                        <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                            <Link
                                                to={`/admin/dashboard/patient-detailbyid/${item?._id}`}
                                                target="_blank"
                                            >

                                                View Details
                                            </Link>



                                        </td>

                                    </tr>
                                </>
                            );
                        })}
                </>
            ) : (
                <td colSpan={8}>
                    <div className="text-red-400 text-center text-[16px] font-semibold my23">
                        No Detail Received.
                    </div>
                </td>
            )}
        </tbody>
    )
}

export default TableBody
