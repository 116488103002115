import React, { useState } from "react";

const Tabs = ({ children, activeTab, setActiveTab }) => {
  // const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();

    setActiveTab(newActiveTab);
  };

  return (
    <div className="mt-4 ">
      {/* <div className="flex max-[995px]:block border-b ml-[10px]  mr-[42px] max-[1100px]:mr-[5px]  border-[#134c49]   "> */}
      <div className="flex flex-col  md:flex-row border-b ml-[10px]  mr-[42px] max-[1100px]:mr-[5px]  border-[#0d5781]   ">

        {children.map((child, index) => (
          <div className="">
            <button
              key={index}
              className={`${activeTab === index
                ? "text-center px-2  py-[4px] text-white text-[16px] font-semibold bg-[#0d5781] border-t-[1px] border-x-[1px] rounded-t-md  "
                : "flex-1 text-white px-2  py-[4px] mx-[2px] max-[995px]:my-[2px]  border-t-[1px] border-x-[1px] bg-[#797877] border-[#0d5781] text-[16px] font-semibold rounded-t-md"
                } `}
              onClick={(e) => {
                handleClick(e, index);

              }}
            >
              {child.props.label}
            </button>
          </div>
        ))}
      </div>
      <div className="py-4">
        {children.map((child, index) => {
          if (index === activeTab) {
            return <div key={child.props.label}>{child.props.children}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, children }) => {
  return (
    <div label={label} className="hidden">
      {children}
    </div>
  );
};
export { Tabs, Tab };
