import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddPatientMedicine from "../../components/modals/AddPatientMedicine";
import {
    FolderOpenIcon,
    PencilSquareIcon,
    PrinterIcon,
} from "@heroicons/react/24/outline";
import PrintPrescriptionModal from "../../components/modals/PrintPriscriptionModal";
import toast from "react-hot-toast";
import { fetch } from "../../../service/utils";
import EditOptoAssement from "../../../website/components/modals/EditOptoAssement";
import { PropagateLoader } from "react-spinners";
import EditPatientMedicine from "../../components/modals/EditPatientMedicine";
import { Printer } from "heroicons-react";
import { formatDated } from "../../../service/date.utils";
import { useSelector } from "react-redux";
import DateWiseSelect from "../DateWiseSelect";

function PatientDoneByDoctorList(props) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [addPatientMedicineOpen, setAddPatientMedicineOpen] = useState(false);
    const [printOpen, setPrintOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [networkError, setNetworkError] = useState("");

    const [patientSuccessData, setPatientSuccessData] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState({});
    const [selectedPrint, setSelectedPrint] = useState({});

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredPatient, setFilteredPatient] = useState([]);

    useEffect(() => {
        setFilteredPatient(patientSuccessData);

    }, [patientSuccessData]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase() || "";
        setSearchQuery(query);

        const filtered =
            patientSuccessData &&
            patientSuccessData?.length > 0 &&
            patientSuccessData?.filter((pt) => pt?.patient?.fName?.toLowerCase().includes(query) || pt?.patient?.contact?.toLowerCase().includes(query));
        setFilteredPatient(filtered);
    };

    const getSuccessPtDetails = async (e) => {
        setIsLoading(true);
        setNetworkError("");
        try {
            const token = localStorage.getItem("beenayi_token");
            const headers = { Authorization: `Bearer ${token}` };
            const response = await fetch("/doctor/getall_success_patients", "post", {
                from:startDate,
                to: endDate,}, headers);
            setIsLoading(false);
            if (response.data.success === false) {
                setIsLoading(false);
                setNetworkError(response.data.message);
            } else {
                setIsLoading(false);
                setPatientSuccessData(response?.data?.data);
            }
        } catch (err) {
              setIsLoading(false);
            const errorMessage =
                err.response?.data?.message ||
                err.message ||
                "Something went wrong. Please try again later.";
              setNetworkError(errorMessage);
            toast.error(errorMessage);
        }
    };

      useEffect(() => {
        if (startDate && endDate) {
            getSuccessPtDetails();
        }
      }, [startDate, endDate]);

    // const handlePrint = async (pt) => {
    //     // event.preventDefault();
    //     try {

    //         const PrescriptionContent = `
    //             <html lang="en">
    //               <head>
    //                 <meta charset="UTF-8">
    //                 <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //                 <style>
    //                   body {
    //                     font-family: Arial, sans-serif;
    //                     margin: 0;
    //                     padding: 0;
    //                     width: 100%;
    //                   }
               
    //                   .container {
    //                     padding: 20px;
    //                     margin: 0 auto;
    //                     width: 90%;
    //                     max-width: 900px;
    //                   }
               
    //                   h1, h2 {
    //                     text-align: center;
    //                     margin-bottom: 10px;
    //                   }
               
    //                   .header, .footer, .body-section {
    //                     width: 100%;
    //                     margin-bottom: 15px;
    //                   }
               
    //                   .header table, .body-section table {
    //                     width: 100%;
    //                     border-collapse: collapse;
    //                     font-size: 14px;
    //                     margin-top: 10px;
    //                   }
               
    //                   table th, table td {
    //                     padding: 8px;
    //                     border: 1px solid #ddd;
    //                     text-align: left;
    //                   }
               
    //                   .section-title {
    //                     font-weight: bold;
    //                     text-decoration: underline;
    //                     margin-bottom: 5px;
    //                     font-size: 14px;
    //                   }
               
    //                   .details {
    //                     display: flex;
    //                     justify-content: space-between;
    //                     font-size: 14px;
    //                   }
               
    //                   .divider {
    //                     border: none;
    //                     border-top: 1px solid #000;
    //                     margin: 10px 0;
    //                   }
               
    //                   .advice, .diagnosis {
    //                     font-weight: bold;
    //                     margin-top: 15px;
    //                   }
    //                 </style>
    //               </head>
    //               <body style="margin-top:200px; margin-bottom:70px">
    //                 <div class="container">
    //                   <!-- Header Section -->
    //                   <div class="header">
                       
    //                     <div class="details">
    //                       <div>
    //                         <p style="margin-top:0px; margin-bottom:4px"><strong>Patient Name:</strong> ${pt?.patient?.fName}</p>
    //                         <p style="margin-top:4px; margin-bottom:4px"><strong>Age/Sex:</strong> ${pt?.patient?.age} years / ${pt?.patient?.gender}</p>
                             
    //                       </div>
    //                       <div>
    //                        <p style="margin-top:4px; margin-bottom:0px"><strong>Contact:</strong> ${pt?.patient?.contact}</p>
    //                         <p style="margin-top:4px; margin-bottom:0px"><strong>Date:</strong> ${formatDated(pt?.patient?.updatedAt)}</p>
                           
    //                       </div>
    //                     </div>
    //                   </div>
               
    //                   <!-- Body Section -->
    //                   <div class="body-section">
    //                     <!-- History Section -->
    //                     <!--<h3 class="section-title">History</h3> -->
    //                     <div class="details">
    //                        <p style="margin-top:4px; margin-bottom:0px">Right Eye Vision: ${pt?.optometrist?.rightEyeVision
    //                         ?.withGlasses}</p>
    //                         <p style="margin-top:4px; margin-bottom:0px">Left Eye Vision: ${pt?.optometrist?.leftEyeVision?.withGlasses}</p>
    //                        <p style="margin-top:4px; margin-bottom:0px">Vision Type: ${pt?.optometrist?.visionType}</p>
    //                       </div>
    //                       <div class="details">
    //                       <p><strong>Chief Complaints:</strong> ${pt?.optometrist?.patientComplaint?.map((cmp, index) => `<span>
    //                                ${cmp},
    //                            </span>
    //                            ` )?.join("")}
    //                        </p>
               
                         
    //                     </div>
    //                     <!-- Glasses Prescription -->
    //                    <div style="display: flex; flex-direction: row; gap: 10px;">
                       
    //                     <div style="width: 49%;">
    //                         <h3 class="section-title">Glasses Prescriptions</h3>
    //                         <table style="border-collapse: collapse; width: 100%;">
    //                             <tr>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Eye</th>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Sph</th>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;text-align: left; font-size:12px">Cyl</th>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Axis</th>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Vision</th>
    //                             </tr>
    //                             <tr>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Right Eye</strong></td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist
    //                                     ?.rightEyeVision?.sph || "N/A"}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.rightEyeVision?.cyl || "N/A"}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.rightEyeVision?.axis || "N/A"}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.rightEyeVision?.visionResult || "N/A"}</td>
    //                             </tr>
    //                             <tr>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Left Eye</strong></td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.leftEyeVision?.sph}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.leftEyeVision?.cyl}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.leftEyeVision?.axis}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.leftEyeVision?.visionResult}</td>
    //                             </tr>
    //                             <tr>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Near Vision (Both Eyes)</strong></td>
    //                                 <td colspan="3" style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: center;">${pt?.optometrist?.leftEyeVision?.nearVision}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: center;">${pt?.optometrist?.leftEyeVision?.nearVisionResult}</td>
    //                             </tr>
    //                         </table>
    //                     </div>
                    
        
                  
         
    //                     <div style="width: 49%;">
    //                         <h3 class="section-title">Ophthalmic Findings</h3>
    //                         <table style="border-collapse: collapse; width: 100%;">
    //                             <tr>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Eye</th>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">IOP</th>
    //                                 <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Syring</th>
    //                             </tr>
    //                             <tr>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Right Eye</strong></td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.eyePressure?.right}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.syringDone?.right}</td>
    //                             </tr>
    //                             <tr>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Left Eye</strong></td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.eyePressure?.left}</td>
    //                                 <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${pt?.optometrist?.syringDone?.left}</td>
    //                             </tr>
    //                         </table>
    //                     </div>
                   
    //             </div>
                   
    //             </div>
               
    //                     <!-- Examination Section -->
    //            <div style="display: flex; flex-direction: row; gap: 10px;">
    //                 <div style="width: 49%;">
    //                     <h3 class="section-title">Slit Lamp Examination</h3>
    //                     <table>
    //                       <tr>
    //                         <th style="width: 20%; padding-top: 2px; padding-bottom: 0px; font-size:12px">Findings</th>
    //                         <th style="width: 80%; padding-top: 2px; padding-bottom: 0px; font-size:12px">${pt?.doctor?.slitlamp}</th>
    //                       </tr>
                          
                         
    //                     </table>
    //                 </div>
    //                 <div style="width: 49%;">
    //                     <h3 class="section-title font-size:12px">Fundus Examination</h3>
    //                     <table>
    //                       <tr>
    //                         <th style="width: 20%; padding-top: 2px; padding-bottom: 0px; font-size:12px">Findings</th>
    //                         <th style="width: 80%; padding-top: 2px; padding-bottom: 0px; font-size:12px">${pt?.doctor?.fundus}</th>
    //                       </tr>
                         
                         
    //                     </table>
    //                 </div>
    //             </div>
               
    //                     <!-- Diagnosis Section -->
    //                     <h3 class="section-title">Diagnosis</h3>
    //                     <p><h5>${pt?.doctor?.diagnose}</h5></p>
                     
    //                   <hr class="divider">
    //             <!-- Medicine -->
    //             <div style="width: 100%;">
    //                 <h3 class="section-title">Medicine</h3>
    //                 <table style="border-collapse: collapse; width: 100%;">
    //                   <tr>
    //                     <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Medication Name</th>
    //                     <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Dose</th>
    //                     <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Frequency</th>
    //                     <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Duration</th>
    //                   </tr>
    //                   <tbody>
    //                   ${pt?.doctor?.medications?.map((item, index) => `
    //                            <tr key=${index}>
    //                                <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.medicationName}</td>
    //                                <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.dose}</td>
                                   
    //                                <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.frequency?.join('<br>')}</td>
    //                                <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.duration?.join('<br>')}</td>
    //                            </tr>
                           
    //                        `)?.join("")}
    //                   </tbody>
    //                 </table>
    //            </div>
               
                          
    //                   <div class="advice">
    //                     <p><strong>Advice:</strong>${pt?.doctor?.advise}</p>
    //                   </div>
    //                    <hr class="divider">
    //                    <div class="advice">
    //                     <p><strong>Next Follow-up:</strong> After ${pt?.doctor?.nextFollowupDate}</p>
    //                   </div>
    //                 </div>
    //               </body>
    //             </html>`;
    //         const iframe = document.createElement("iframe");
    //         document.body.appendChild(iframe);

    //         const style = `
    //                 <style>
    //                     @page {
    //                         margin: 0; /* Removes margins that typically include title, URL, or date */
    //                     }
    //                     body {
    //                         margin: 0;
    //                         padding: 0;
    //                     }
    //                 </style>
    //             `;


    //         const content = `
    //             ${style}
    //             ${PrescriptionContent}
    //         `;

    //         iframe.contentWindow.document.open();
    //         iframe.contentWindow.document.write(content);
    //         iframe.contentWindow.document.close();
    //         iframe.contentWindow.focus();
    //         iframe.contentWindow.print();
    //         iframe.remove();
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };
    return (
        <>
            {/* <Head> */}
            {/* <title></title> */}
            {/* </Head> */}

            <div className="row ">
                <div className="min-w-[95%] flex flex-col md:flex-row">
                    <nav
                        className="flex items-center  w-[80%] rounded-md py-2 px-2 mt-4 mb-1"
                        aria-label="Breadcrumb"
                    >
                        <ol className="flex items-center space-x-1 md:space-x-3">
                            <li className="flex items-center">
                                <>
                                    <Link
                                        to="/admin/dashboard"
                                        className="inline-flex items-center font-semibold text-xl text-gray-700 hover:text-gray-900 hover:underline"
                                    >
                                        Dashboard
                                    </Link>

                                    <svg
                                        className="w-6 h-6 text-gray-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </>

                                <span className="inline-flex items-center text-lg text-gray-700">
                                    Today Success Patient List
                                </span>
                            </li>
                        </ol>
                    </nav>
                    <DateWiseSelect
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                </div>
                <section className="pr-4 pt-8 pb-20">
                    <div className="inline-block w-full  border-[1px] border-gray-300 overflow-auto">


                        <div className="overflow-auto">
                            <div className="flex min-w-full  items-center justify-between py-2 mb-3 bg-[#0d5781]">
                                <div className="font-semibold text-lg px-3 text-white ">
                                    Today Success Patient List
                                </div>
                            </div>
                            <div className="flex min-w-full items-center justify-end  mb-2">
                                <div className="border-[1px] border-[#0d5781] w-[30%] rounded-[4px] mr-10">
                                    <input
                                        type="text"
                                        placeholder="Search Name, Contact..."
                                        className="px-2 py-[5px] rounded-[4px] w-full "
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            {isLoading ? (
                                <div className="flex justify-center h-screen items-center">
                                    <PropagateLoader color="#36d7b7" loading={true} size={15} />
                                </div>
                            ) : networkError ? (
                                <div className="text-red-600 text-center mt-16 font-semibold">
                                    {networkError}
                                </div>
                            ) : (

                                <div className="overflow-auto">
                                    <table class="table table-hover text-[12px]">
                                        <thead className="bg-[#0d5781] text-white">
                                            <tr>
                                                <th scope="col" className="w-[3%] font-medium">
                                                    #
                                                </th>
                                                <th scope="col" className="w-[12%] font-medium">
                                                    Full Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-[3%] text-center font-medium"
                                                >
                                                    Age
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-[5%] text-center font-medium"
                                                >
                                                    Gender
                                                </th>
                                                <th scope="col" className="w-[5%]  font-medium">
                                                    Contact
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-[7%] text-center font-medium"
                                                >
                                                    Advice Details
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-[9%] text-center font-medium"
                                                >
                                                    Advice
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-[8%] text-center font-medium"
                                                >
                                                    Next Followup
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="w-[28%]  text-start font-medium"
                                                >
                                                    Medications
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="w-[10%] text-center  font-medium"
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredPatient &&
                                                filteredPatient?.length > 0 &&
                                                filteredPatient?.map((pt, ind) => {
                                                    return (
                                                        <tr>
                                                            <td
                                                                scope="row"
                                                                className="text-center border-[1px]"
                                                            >
                                                                {ind + 1}
                                                            </td>
                                                            <td>
                                                                {pt?.patient?.fName}
                                                                {""}
                                                                {pt.patient?.lName}
                                                            </td>
                                                            <td className="text-center border-[1px]">
                                                                {pt.patient?.age}
                                                            </td>
                                                            <td className="text-center border-[1px]">
                                                                {pt.patient?.gender}
                                                            </td>
                                                            <td className="border-[1px]">
                                                                {pt.patient?.contact}
                                                            </td>

                                                            <td className="border-[1px]">

                                                                {pt?.doctor?.adviceDetails && (
                                                                    <>
                                                                        {Object.entries(pt?.doctor?.adviceDetails)?.map(
                                                                            ([key, value], index) => (
                                                                                <span key={index}>
                                                                                    {value},
                                                                                    <br />
                                                                                </span>
                                                                            )
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td className="border-[1px]">
                                                                {pt?.doctor?.advise}
                                                            </td>
                                                            <td className="border-[1px]">
                                                                {pt?.doctor?.nextFollowupDate}
                                                            </td>

                                                            <td className="border-[1px]">
                                                                {pt?.doctor?.medications[0]?.medicationName === "-" ? null : (
                                                                    <>

                                                                        <thead className="bg-[#0d5781] text-white text-[8px]">
                                                                            <tr>
                                                                                <th scope="col" className="w-[3%] font-medium">
                                                                                    #
                                                                                </th>
                                                                                <th scope="col" className="w-[20%] font-medium">
                                                                                    Name
                                                                                </th>
                                                                                <th scope="col" className="w-[7%] font-medium">
                                                                                    Dose
                                                                                </th>
                                                                                <th scope="col" className="w-[7%] font-medium">
                                                                                    Frequency
                                                                                </th>
                                                                                <th scope="col" className="w-[7%] font-medium">
                                                                                    Duration
                                                                                </th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {pt?.doctor?.medications?.map((md, ind) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={ind}>
                                                                                            <td className="border-[1px]">
                                                                                                {ind + 1}
                                                                                            </td>

                                                                                            <td className="border-[1px]">
                                                                                                {md?.medicationName}
                                                                                            </td>
                                                                                            <td className="border-[1px]">
                                                                                                {md?.dose}
                                                                                            </td>
                                                                                            <td className="border-[1px]">
                                                                                                {Array?.isArray(md?.frequency)
                                                                                                    ? md?.frequency?.join(", ")
                                                                                                    : md?.frequency}
                                                                                            </td>
                                                                                            <td className="border-[1px]">
                                                                                                {Array?.isArray(md?.duration)
                                                                                                    ? md?.duration?.join(", ")
                                                                                                    : md?.duration}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )

                                                                            })}
                                                                        </tbody>
                                                                    </>
                                                                )}
                                                            </td>

                                                            <td className="text-center border-[1px]">
                                                                <div className="flex justify-center items-center space-x-2">
                                                                    <PencilSquareIcon
                                                                        className="w-5 h-5 ml-3 text-blue-500 hover:text-red-600 cursor-pointer"
                                                                        onClick={() => {
                                                                            setSelectedPatient(pt);
                                                                            setAddPatientMedicineOpen(true);
                                                                        }}
                                                                    />


                                                                    <PrinterIcon
                                                                        className="w-5 h-5 ml-3 text-green-500 hover:text-red-600 cursor-pointer"
                                                                        onClick={() => {
                                                                            setPrintOpen(true)
                                                                            setSelectedPrint(pt);
                                                                        }}
                                                                    />
                                                                    <Link
                                                                        to={`/admin/dashboard/patient-detailbyid/${pt?.patient?._id}`}
                                                                        target="_blank"
                                                                    >
                                                                        <FolderOpenIcon className="w-5 h-5 mr-2 text-[#0d5781] hover:text-red-600 cursor-pointer" />
                                                                    </Link>


                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>

                    </div>
                </section>
            </div>
            <EditPatientMedicine
                selectedPatient={selectedPatient}
                addPatientMedicineOpen={addPatientMedicineOpen}
                setAddPatientMedicineOpen={setAddPatientMedicineOpen}
                getPtDetails={getSuccessPtDetails}
                doctorId={selectedPatient?.doctor?._id}
            />
            <PrintPrescriptionModal
                doctorId={selectedPrint?.doctor?._id}
                printOpen={printOpen}
                setPrintOpen={setPrintOpen}
                selectedPrint={selectedPrint}
            />

        </>
    );
}

export default PatientDoneByDoctorList;
