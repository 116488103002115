import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { useInput } from "../../../hook/input-hook";
import {
  couponValueNumber,
  emailreg,
  numberreg,
  pincodeNumber,
} from "../../../service/validations/validation";
import { MoonLoader } from "react-spinners";
import { fetch } from "../../../service/utils";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import Input from "../../../admin/components/input/Input.component";
import { roles } from "../../../service/constant";

const isFName = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your First Name" };
  } else {
    return { validated: true, message: "" };
  }
};

const isLName = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Last Name" };
  } else {
    return { validated: true, message: "" };
  }
};

const isEmail = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Email" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "Your Email is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};

const isContact = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Contact Number" };
  } else if (!numberreg.test(value)) {
    return { validated: false, message: "Enter 10 digits Contact number" };
  } else {
    return { validated: true, message: "" };
  }
};
const isAge = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Age" };
  } else if (!couponValueNumber.test(value)) {
    return { validated: false, message: "Your Age is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};

const isCity = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your City" };
  } else {
    return { validated: true, message: "" };
  }
};
const isAddress = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Address" };
  } else {
    return { validated: true, message: "" };
  }
};

const isPinCode = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Pin Code Number" };
  } else if (!pincodeNumber.test(value)) {
    return { validated: false, message: "Enter 6 digits only Pin Code Number" };
  } else {
    return { validated: true, message: "" };
  }
};

export default function EditLoginUserInfo({
  addVisionDetail,
  setAddVisionDetail,
  patientId,
  editOptoDetail,
  optoDetails
}) {
  const cancelButtonRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [fNameTouch, setFNameTouch] = useState("");
  const [lNameTouch, setLNameTouch] = useState("");
  const [emailTouch, setEmailTouch] = useState("");
  const [contactTouch, setContactTouch] = useState("");
  const [ageTouch, setAgeTouch] = useState("");
  const [cityTouch, setCityTouch] = useState("");
  const [addressTouch, setAddressTouch] = useState("");
  const [pinCodeTouch, setPinCodeTouch] = useState("");

  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");

  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleIdError, setSelectedRoleIdError] = useState("");

  const handleRoleChange = (event) => {
    setSelectedRoleId(event.target.value);
    setSelectedRoleIdError("");
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setGenderError("");
  };

  const {
    hasError: hasErrorFName,
    enterValue: enterValueFName,
    message: FNameMessage,
    onChangeHandler: onChangeHandlerFName,
    reset: resetFName,
    isTouch: isTouchFName,
    setDirect: setDirectFName,
    onBlurHandler: onBlurHandlerFName,
  } = useInput(isFName, setNetworkError, setFNameTouch);

  const {
    hasError: hasErrorLName,
    enterValue: enterValueLName,
    message: LNameMessage,
    onChangeHandler: onChangeHandlerLName,
    reset: resetLName,
    isTouch: isTouchLName,
    setDirect: setDirectLName,
    onBlurHandler: onBlurHandlerLName,
  } = useInput(isLName, setNetworkError, setLNameTouch);

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    message: EmailMessage,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    isTouch: isTouchEmail,
    setDirect: setDirectEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouch);

  const {
    hasError: hasErrorContact,
    enterValue: enterValueContact,
    message: ContactMessage,
    onChangeHandler: onChangeHandlerContact,
    reset: resetContact,
    isTouch: isTouchContact,
    setDirect: setDirectContact,
    onBlurHandler: onBlurHandlerContact,
  } = useInput(isContact, setNetworkError, setContactTouch);
  const {
    hasError: hasErrorAge,
    enterValue: enterValueAge,
    message: AgeMessage,
    onChangeHandler: onChangeHandlerAge,
    reset: resetAge,
    isTouch: isTouchAge,
    setDirect: setDirectAge,

    onBlurHandler: onBlurHandlerAge,
  } = useInput(isAge, setNetworkError, setAgeTouch);

  const {
    hasError: hasErrorCity,
    enterValue: enterValueCity,
    message: CityMessage,
    onChangeHandler: onChangeHandlerCity,
    reset: resetCity,
    isTouch: isTouchCity,
    setDirect: setDirectCity,

    onBlurHandler: onBlurHandlerCity,
  } = useInput(isCity, setNetworkError, setCityTouch);
  const {
    hasError: hasErrorAddress,
    enterValue: enterValueAddress,
    message: AddressMessage,
    onChangeHandler: onChangeHandlerAddress,
    reset: resetAddress,
    isTouch: isTouchAddress,
    setDirect: setDirectAddress,
    onBlurHandler: onBlurHandlerAddress,
  } = useInput(isAddress, setNetworkError, setAddressTouch);
  const {
    hasError: hasErrorPinCode,
    enterValue: enterValuePinCode,
    message: PinCodeMessage,
    onChangeHandler: onChangeHandlerPinCode,
    reset: resetPinCode,
    isTouch: isTouchPinCode,
    setDirect: setDirectPinCode,
    onBlurHandler: onBlurHandlerPinCode,
  } = useInput(isPinCode, setNetworkError, setPinCodeTouch);

  useEffect(() => {
    setSuccessMessage("");
  }, [addVisionDetail]);

  useEffect(() => {
    setDirectFName(editOptoDetail?.fName);
    setDirectLName(editOptoDetail?.lName);
    setDirectAge(editOptoDetail?.age);
    setDirectEmail(editOptoDetail?.email);
    setDirectContact(editOptoDetail?.phone);
    setDirectAddress(editOptoDetail?.address);
    setDirectCity(editOptoDetail?.city);
    setDirectPinCode(editOptoDetail?.pin);
    setGender(editOptoDetail?.gender)
    setSelectedRoleId(editOptoDetail?.roleId)
  }, [addVisionDetail, patientId]);

  const editLoginUserDetails = async () => {
    setLoading(true);
    setNetworkError("");
    setLoadingMessage("Updating an Entry...");

    if (
      hasErrorFName === true ||
      hasErrorLName === true ||
      hasErrorAge === true ||
      hasErrorEmail === true ||
      hasErrorAddress === true ||
      hasErrorCity === true ||
      hasErrorPinCode === true ||
      hasErrorContact === true
    ) {
      if (!isTouchFName) {
        setFNameTouch("Please Enter Your First Name!");
        setLoading(false);
        return false;
      }
      if (!isTouchLName) {
        setLNameTouch("Please Enter Your Last Name!");
        setLoading(false);
        return false;
      }
      if (!isTouchEmail) {
        setEmailTouch("Please Enter Your Email!");
        setLoading(false);
        return false;
      }
      if (!isTouchAge) {
        setAgeTouch("Please Enter Your Age!");
        setLoading(false);
        return false;
      }
      if (!isTouchContact) {
        setContactTouch("Please Enter Your Contact Number!");
        setLoading(false);
        return false;
      }
      if (!isTouchAddress) {
        setAddressTouch("Please Enter Your Address!");
        setLoading(false);
        return false;
      }
      if (!isTouchCity) {
        setCityTouch("Please Enter Your City!");
        setLoading(false);
        return false;
      }
      if (!isTouchPinCode) {
        setPinCodeTouch("Please Enter Your Pin Code!");
        setLoading(false);
        return false;
      }
    }
    if (!gender) {
      setLoading(false);
      setGenderError("Please Select Your Gender!");
      return false;
    }
    if (!selectedRoleId) {
      setSelectedRoleIdError("Please Select a Role Name.");
      setLoading(false);
      return false;
    }
    try {
      const body = {
        id: editOptoDetail?._id,
        fName: enterValueFName,
        lName: enterValueLName,
        phone: enterValueContact,
        email:enterValueEmail,
        address: enterValueAddress,
        city: enterValueCity,
        pin: enterValuePinCode,
        age: enterValueAge,
        gender: gender,
        roleId: selectedRoleId,
        //   password,
      };
      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        `/admin/update/${editOptoDetail._id}`,
        "put",
        body,
        headers
      );
      if (response.ok === false) {
        setLoading(false);
        setNetworkError(response.data.message);
      }
      setLoading(false);
      setSuccessMessage(response.data.message);

      setAddVisionDetail(false);
      optoDetails();
      toast.success("Successfully Update user Details!");
      resetfield();
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  const addHandler = () => {
    editLoginUserDetails();
  };

  const resetfield = () => {
    setNetworkError("");
    resetFName();
    resetLName();
    resetAge();
    resetEmail();
    resetContact();
    resetAddress();
    resetCity();
    resetPinCode();
    setFNameTouch("");
    setLNameTouch("");
    setAgeTouch("");
    setEmailTouch("");
    setContactTouch("");
    setAddressTouch("");
    setCityTouch("");
    setGender("");
    setSelectedRoleId("");
    setPinCodeTouch("");
  };

  return (
    <Transition.Root show={addVisionDetail} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setAddVisionDetail}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex mt-10 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" max-[400px]:w-[100%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[60%] sm:w-[60%] md:w-[60%] xl:w-[60%]">
                <div className="flex flex-row justify-between items-center">
                  <div className=" px-4 text-start  bg-white">
                    <h2 className="text-gray-600 text-lg font-semibold    ">
                      Edit User Details: {editOptoDetail?.fName}
                    </h2>
                  </div>
                  <div className="px-4 py-3 sm:px-6  ">
                    <button
                      type="button"
                      className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900  focus:outline-none  "
                      onClick={() => {
                        setAddVisionDetail(false);
                        resetfield();
                      }}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                <div className="mt-4 w-full px-4 max-[640px]:px-4 sm:px-24 md:px-32 lg:px-44 xl:px-52  ">
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {" First Name"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter First Name"
                          id="fname"
                          name="fname"
                          enterValue={enterValueFName}
                          onChangeHandler={onChangeHandlerFName}
                          hasError={hasErrorFName}
                          errorMessage={FNameMessage}
                          isTouch={fNameTouch}
                          setIsTouch={setFNameTouch}
                          onBlurHandler={onBlurHandlerFName}
                        />
                      </div>

                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Last Name"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter Last Name"
                          id="lname"
                          name="lname"
                          enterValue={enterValueLName}
                          onChangeHandler={onChangeHandlerLName}
                          hasError={hasErrorLName}
                          errorMessage={LNameMessage}
                          isTouch={lNameTouch}
                          setIsTouch={setLNameTouch}
                          onBlurHandler={onBlurHandlerLName}
                        />
                      </div>
                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Age"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter Last Name"
                          id="Age"
                          name="Age"
                          enterValue={enterValueAge}
                          onChangeHandler={onChangeHandlerAge}
                          hasError={hasErrorAge}
                          errorMessage={AgeMessage}
                          isTouch={ageTouch}
                          setIsTouch={setAgeTouch}
                          onBlurHandler={onBlurHandlerAge}
                        />
                      </div>
                      <div className="w-[100%] mt-3">
                        <div className="flex items-center justify-between mb-2">
                          <label
                            htmlFor="gender"
                            className="block text-sm font-bold leading-6 text-gray-900"
                          >
                            Gender
                            <span className="text-red-600 font-bold"> *</span>
                          </label>
                        </div>

                        <select
                          id="gender"
                          name="gender"
                          value={gender}
                          onChange={handleGenderChange}
                          className="block w-full rounded-md border border-gray-300 py-[10px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                        >
                          <option value="" disabled>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {genderError && (
                          <div className="flex ml-[0.5px]">
                            <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                            <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                              {genderError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1  md:grid-cols-3">
                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Email"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                          enterValue={enterValueEmail}
                          onChangeHandler={onChangeHandlerEmail}
                          hasError={hasErrorEmail}
                          errorMessage={EmailMessage}
                          isTouch={emailTouch}
                          setIsTouch={setEmailTouch}
                          onBlurHandler={onBlurHandlerEmail}
                        />
                      </div>
                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Contact"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter Last Name"
                          id="Contact"
                          name="Contact"
                          enterValue={enterValueContact}
                          onChangeHandler={onChangeHandlerContact}
                          hasError={hasErrorContact}
                          errorMessage={ContactMessage}
                          isTouch={contactTouch}
                          setIsTouch={setContactTouch}
                          onBlurHandler={onBlurHandlerContact}
                        />
                      </div>
                      <div className="w-[100%] mt-[8px]">
                        <div className="block text-sm font-bold leading-6 text-gray-900 my-2">
                          Select Role{" "}
                          <span className="text-red-600 font-bold"> *</span>
                        </div>
                        <select
                          value={selectedRoleId}
                          onChange={handleRoleChange}
                          className="{`block w-full rounded-md border border-gray-300 py-[10px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium "
                        >
                          <option value="" disabled>
                            ---Select a role---
                          </option>
                          {roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                        {selectedRoleIdError && (
                          <div className="flex ml-[0.5px]">
                            <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                            <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                              {selectedRoleIdError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1 md:grid-cols-3">
                      <div className="w-[100%] mt-3">
                        <div className="flex items-center justify-between">
                          <label className="block text-sm font-bold leading-6 text-gray-900 mb-2">
                            Address
                            <span className="text-red-600 font-bold"> *</span>
                          </label>
                        </div>
                        <textarea
                          type="text"
                          rows={1}
                          placeholder="Enter Address"
                          id="Address"
                          name="Address"
                          className="block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                          onChange={onChangeHandlerAddress}
                          value={enterValueAddress}
                          onBlur={() => {
                            onBlurHandlerAddress();
                            setAddressTouch("");
                          }}
                        />
                        {hasErrorAddress && (
                          <div className="flex ml-[0.5px]">
                            <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                            <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                              {AddressMessage}
                            </p>
                          </div>
                        )}
                        {addressTouch && (
                          <div className="flex ml-[0.5px]">
                            <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                            <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                              {addressTouch}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"City"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter City Name"
                          id="City"
                          name="City"
                          enterValue={enterValueCity}
                          onChangeHandler={onChangeHandlerCity}
                          hasError={hasErrorCity}
                          errorMessage={CityMessage}
                          isTouch={cityTouch}
                          setIsTouch={setCityTouch}
                          onBlurHandler={onBlurHandlerCity}
                        />
                      </div>
                      <div className="w-[100%] mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Pin Code"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Enter Pin Code"
                          id="pincode"
                          name="pincode"
                          enterValue={enterValuePinCode}
                          onChangeHandler={onChangeHandlerPinCode}
                          hasError={hasErrorPinCode}
                          errorMessage={PinCodeMessage}
                          isTouch={pinCodeTouch}
                          setIsTouch={setPinCodeTouch}
                          onBlurHandler={onBlurHandlerPinCode}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      {isLoading && (
                        <strong className="text-blue-800 text-sm  text-center">
                          {loadingMessage}
                        </strong>
                      )}
                      {networkError && (
                        <strong className="text-red-600 text-sm  text-center">
                          {networkError}
                        </strong>
                      )}
                      {successMessage && (
                        <strong className="text-green-800 text-sm  text-center">
                          {successMessage}
                        </strong>
                      )}
                    </div>
                    <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        disabled={isLoading}
                        className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#10a659] hover:bg-[#0d5781] "
                        onClick={() => {
                          addHandler();
                        }}
                      >
                        {isLoading === true ? (
                          <MoonLoader
                            color="#fff"
                            loading={isLoading}
                            size={20}
                            className="mx-1"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          resetfield();
                          setAddVisionDetail(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
