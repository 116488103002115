import React from 'react'

function TableHead() {
    return (
        <thead className="md-w-full border-b text-white bg-[#0d5781] font-medium">
            <tr>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    #
                </th>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    Full Name
                </th>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    Age
                </th>

                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    Gender
                </th>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    Contact
                </th>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    Payment Mode
                </th>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    City
                </th>
                <th className="p-2 text-left text-sm font-semibold tracking-wider">
                    View Details
                </th>
            </tr>
        </thead>
    )
}

export default TableHead
