import React, { useEffect, useState } from "react";
import {
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { fetch } from "../../service/utils";
import { formatDated } from "../../service/date.utils";
import OptoPatientHistoryModal from "../../website/components/modals/OptoPatientHistoryModal";

function PatientDetailsByIdAdmin(props) {
  const { patient_id } = useParams();
  const [addVisionDetail, setAddVisionDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");


  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPatient, setFilteredPatient] = useState([]);
  const [optoData, setOptoData] = useState([]);
  const [editOptoDetail, setEditOptoDetail] = useState({})
  const [optoId, setOptoId] = useState("")


  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase() || "";
    setSearchQuery(query);

    const filtered =
      [] &&
      [].length > 0 &&
      []?.filter((pt) => pt?.fname?.toLowerCase().includes(query));
    setFilteredPatient(filtered);
  };

  const getOptoDetails = async (e) => {
    setIsLoading(true);
    setNetworkError("");

    try {
      const body = { patient_id };
      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/optometrist/get_opto_assessmentbyid", "post", body, headers);
      setIsLoading(false);
      if (response.data.success === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setOptoData(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again later.";
      setNetworkError(errorMessage);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    getOptoDetails();
  }, []);

  return (
    <>
      <section className="-mx-4 flex flex-wrap p-4">
        <div className="inline-block w-full  border-[1px] border-gray-300 overflow-auto">
          <div className="overflow-auto">
            <div className="flex min-w-full  items-center justify-between py-2 mb-3 bg-[#0d5781]">
              <div className="font-semibold text-lg px-3 text-white ">
                Patient Detail
              </div>

              <Link to="/admin/dashboard/patient-optometrist" className="mr-10">
                <button className="px-3 py-[6px]  text-base font-medium text-white bg-[#10a659] rounded-md shadow-md focus:outline-none focus:ring-2">
                  Optometrist List
                </button>
              </Link>
            </div>
            <tr className="font-semibold  ">
              <td scope="row" className="text-center py-2 px-3 ">
                {optoData[0]?.fName}
              </td>
              <td scope="row" className="text-center py-2 px-3 ">
                {optoData[0]?.age}
              </td><td scope="row" className="text-center py-2 px-3 ">
                {optoData[0]?.gender}
              </td><td scope="row" className="text-center py-2 px-3 ">
                {optoData[0]?.contact}
              </td>
              <td scope="row" className="text-center py-2 px-3 ">
                {optoData[0]?.city}
              </td>
              <td scope="row" className="text-center py-2 px-3 ">

                {optoData &&
                  optoData[0]?.payId === 1 ? (
                  <>
                    <span>Cash</span>
                  </>
                ) : optoData[0]?.payId === 2 ? (
                  <>
                    <span>UPI</span>
                  </>
                ) : optoData[0]?.payId === 3 ? (
                  <>
                    <span>Follow-Up</span>
                  </>
                ) : optoData[0]?.payId === 4 ? (
                  <>
                    <span>Complimentary</span>
                  </>
                ) : null}
              </td>

            </tr>
            <div className="overflow-auto">
              <table class="table table-hover">
                <thead className="bg-[#0d5781] text-white">
                  <tr>
                    <th scope="col" className="w-[3%] text-center font-medium">
                      #
                    </th>
                    <th scope="col" className="w-[8%] text-center font-medium">
                      Date
                    </th>
                    <th scope="col" className="w-[20%] text-start font-medium">
                      Left Eye
                    </th>
                    <th scope="col" className="w-[20%] text-start font-medium">
                      Right Eye
                    </th>
                    <th scope="col" className="w-[10%] text-start font-medium">
                      Complaints
                    </th>

                    <th
                      scope="col"
                      className="w-[5%] text-center  font-medium"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {optoData &&
                    optoData[0]?.assessments?.length > 0 &&
                    optoData[0]?.assessments?.map((pt, ind) => {
                      return (
                        <tr>
                          <td scope="row" className="text-center border-[1px]">
                            {ind + 1}
                          </td>
                          <td>{formatDated(pt?.updatedAt)} </td>
                          <td className="text-start border-[1px]">{pt?.leftEyeVision && (
                            <>
                              {Object.entries(pt?.leftEyeVision)?.map(
                                ([key, value], index) => (
                                  <span key={index}>
                                    {key}: {value}
                                    <br />
                                  </span>
                                )
                              )}
                            </>
                          )}</td>
                          <td className="text-start border-[1px]">{pt?.leftEyeVision && (
                            <>
                              {Object.entries(pt.rightEyeVision).map(
                                ([key, value], index) => (
                                  <span key={index}>
                                    {key}: {value}
                                    <br />
                                  </span>
                                )
                              )}
                            </>
                          )}</td>
                          <td className="border-[1px]"> {Array?.isArray(pt?.patientComplaint)
                            ? pt?.patientComplaint?.join(", ")
                            : pt?.patientComplaint}</td>


                          <td className="text-center border-[1px]">
                            <div className="flex justify-center items-center space-x-2">
                              <BookOpenIcon
                                className="w-6 h-6 ml-2 text-[#10a659] hover:text-blue-800 cursor-pointer"
                                onClick={() => { setEditOptoDetail(pt); setAddVisionDetail(true); }}
                              />


                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <OptoPatientHistoryModal
        addVisionDetail={addVisionDetail}
        setAddVisionDetail={setAddVisionDetail}
        editOptoDetail={editOptoDetail}
        optoDetails={ optoData[0]}
        patientId={editOptoDetail?.patientId?._id}
      />

    </>
  );
}

export default PatientDetailsByIdAdmin;
