import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { fetch } from "../../service/utils";
import toast from "react-hot-toast";

const ToggleSwitch = ({ id, changeStatus, url, getData, enbData }) => {
  const [enabled, setEnabled] = useState(changeStatus == 1 ? true : false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setEnabled(changeStatus == 1 ? true : false);
  }, [changeStatus]);

  const activateHandler = () => {
    const changeStatus = async () => {
      setErrorMessage("");
      try {
        const token = localStorage.getItem("beenayi_token");
        const headers = { Authorization: `Bearer ${token}` };
        const body =
          enbData && typeof enbData === "object"
            ? { data: enbData, publish_status: !enabled }
            : { publish_status: !enabled };
        const response = await fetch(`${url}${id}`, "patch", body, headers);
        if (response.ok === false) {
          setEnabled(false);
          setErrorMessage(response.data.message);
        }
        getData();
        toast.success(response.data.message);
      } catch (err) {
        setEnabled(false);
        if (
          err?.response &&
          err?.response?.data &&
          err?.response?.data?.message
        ) {
          setErrorMessage(err?.response?.data?.message);
        } else {
          setErrorMessage("Unable to update status.");
        }
      } finally {
      }
    };
    changeStatus();
  };

  return (
    <>
      <Switch
        onClick={activateHandler}
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? "bg-teal-700" : "bg-red-700"}
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-9" : "translate-x-0"}
          pointer-events-none inline-block h-[22px] mb-[1.65px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <br />
      {errorMessage && (
        <strong className="text-red-500 text-sm font-bold italic text-center">
          {errorMessage}
        </strong>
      )}
    </>
  );
};

export default ToggleSwitch;
