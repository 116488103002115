export const padZeroToMonth = (date) => {
  return date.replace(/-(\d)-/, (match, p1) => `-${p1.padStart(2, "0")}-`);
};

export function getDayOfWeek(dateStr) {
  const [day, month, year] = dateStr.split("-");
  const date = new Date(year, month - 1, day); // JavaScript months are 0-indexed
  return date.getDay() || 7; // Adjust Sunday from 0 to 7
}

export function convertTo12HourFormat(timeStr) {
  const [hours, minutes] = timeStr?.split(":");
  const date = new Date();
  date.setHours(+hours, +minutes, 0); // Set hours and minutes, seconds to 0
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function padDate(inputDate) {
  // Split the date string by the dash separator
  const parts = inputDate.split("-");

  // Check the structure of the input date to determine the format
  if (parts[0].length === 4) {
    // Format is YYYY-M-D
    let year = parts[0];
    let month = parts[1].length === 1 ? "0" + parts[1] : parts[1];
    let day = parts[2].length === 1 ? "0" + parts[2] : parts[2];
    return `${year}-${month}-${day}`;
  } else {
    // Format is M-D-YYYY
    let month = parts[0].length === 1 ? "0" + parts[0] : parts[0];
    let day = parts[1].length === 1 ? "0" + parts[1] : parts[1];
    let year = parts[2];
    return `${month}-${day}-${year}`;
  }
}


export function formatDatedCorrect(dateString) {
  const date = new Date(dateString);

  // Extract individual date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Extract time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Determine AM/PM
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format
  hours = String(hours).padStart(2, '0');

  // Construct formatted date string
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${amPm}`;
}
export function formatDated(dateString) {
  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  const date = new Date(new Date(dateString).getTime() - istOffset);

  // Extract individual date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Extract time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Determine AM/PM
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format
  hours = String(hours).padStart(2, '0');

  // Construct formatted date string
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${amPm}`;
}


export function getFormattedDateTime() {
  const now = new Date();
  // Get date components
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = now.getFullYear();
  // Get time components
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // If hour is 0, set it to 12

  const formattedHours = String(hours).padStart(2, '0');

  return `${day}-${month}-${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
}


export function calculateDaysBetweenDates(date1String, date2String) {
  const parseDate = (dateString) => {
    const [datePart] = dateString.split(' '); // Extract only the date part
    const [day, month, year] = datePart.split('-').map(Number); // Parse the date
    return new Date(year, month - 1, day); // Normalize to midnight
  };

  const date1 = parseDate(date1String);
  const date2 = parseDate(date2String);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(date1 - date2);

  // Convert milliseconds to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return Math.floor(differenceInDays); // Return only whole days
}

export const getCurrentDateAndTimeIndia = () => {
  const date = new Date();
  const indiaOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds
  const indiaTime = new Date(date.getTime() + indiaOffset);
  return indiaTime;
};

