/** @format */

import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import TokenMiddleware from "../middleware/middleware";
import LoginMiddleware from "../middleware/login-middleware";
import LoginAdmin from "../pages/LoginAdmin";
import ErrorPage from "../pages/ErrorPage";
import Navbar from "../layout/Navbar";
import AdminTokenMiddleware from "../../middleware/admin-token-middleware";
import ViewAll from "../../website/pages/reception/RegistrationDashBoard";

import Optometrist from "../../website/pages/optometrist/Optometrist";
import OptometristDashboard from "../../website/pages/optometrist/OptometristDashboard";
import RegisterPatient from "../pages/RegisterPatient";
import RegisterPatientDashboard from "../pages/RegisterPatientDashboard";
import OptometristList from "../pages/OptometristList";
import OptometristDashboardList from "../pages/OptometristDashboardList";
import LoginUserDetails from "../pages/masters/LoginUserDetails";
import DashboardDetailHistory from "../pages/masters/DashboardDetailHistory";
import PatientDetailsByIdAdmin from "../pages/PatientDetailsByIdAdmin";
import PatientDoneByDoctorList from "../pages/masters/PatientDoneByDoctorList";
const AdminDashboard = lazy(() => import("../pages/AdminDashboard"));
const AdminChangePwd = lazy(() => import("../pages/AdminChangePwd"));

const Admin = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            // <LoginMiddleware>
            <LoginAdmin />
            // </LoginMiddleware>
          }
        />
        {/* <Route
          path="login"
          element={
            // <LoginMiddleware>
            <LoginAdmin />
            // </LoginMiddleware>
          }
        /> */}
        {/* <Route
          path="dashboard/change_password"
          element={
            <AdminTokenMiddleware>

              <Navbar />
              <AdminChangePwd />
              </AdminTokenMiddleware>

          }
        /> */}
        <Route
          path="dashboard/*"
          element={
            <AdminTokenMiddleware>
              <>
                {" "}
                <Navbar />
                <AdminDashboard />
              </>
            </AdminTokenMiddleware>
          }
        />
        <Route
          path="/user-login-info"
          element={
            <LoginUserDetails />
          }
        />
        <Route
          path="/user-login-info"
          element={
            <LoginUserDetails />
          }
        />
        <Route
          path="tabs-details"
          element={
            <DashboardDetailHistory />
          }
        />
        <Route
          path="/patient-registrationDashboard"
          element={
            <RegisterPatientDashboard />

          }
        />
        <Route
          path="/patient-optometrist"
          element={
            <OptometristList />
          }
        />
        <Route
          path="/patient-optometrist_dashboard"
          element={
            <OptometristDashboardList />
          }
        />
        <Route
          path="/patient-details/:patient_id"
          element={
            <PatientDetailsByIdAdmin />
          }
        />
 <Route
          path="/patient-successfull"
          element={
            <PatientDoneByDoctorList />
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Admin;
