import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useInput } from "../../../hook/input-hook";
import toast from "react-hot-toast";
import { fetch } from "../../../service/utils";
import Input from "../../../admin/components/input/Input.component";
import {
  couponValueNumber,
  emailreg,
  numberreg,
} from "../../../service/validations/validation";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { MoonLoader } from "react-spinners";

import backgroundImage from '../../../assets/img/bg_eye.jpg';
import SearchPatient from "./SearchPatient";
import PatientFollowUp from "../../components/modals/PatientFollowUp";


const isFName = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your First Name" };
  } else {
    return { validated: true, message: "" };
  }
};

const isContact = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Contact Number" };
  } else if (!numberreg.test(value)) {
    return { validated: false, message: "Enter 10 digits Contact number" };
  } else {
    return { validated: true, message: "" };
  }
};
const isAge = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Age" };
  } else if (!couponValueNumber.test(value)) {
    return { validated: false, message: "Your Age is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};

const isCity = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your City" };
  } else {
    return { validated: true, message: "" };
  }
};

export default function PatientRegistration() {
  const navigate = useNavigate();
  // --------------------------------------Start search patient details-------------------------------
  const [followUpOpen, setFollowUpOpen] = useState(false);
  const [patientfollowUpData, setPatientfollowUpData] = useState("");
    const [value, setValue] = useState("");


  // --------------------------------------End search patient details-------------------------------
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [gender, setGender] = useState("");
  const [payment, setPayment] = useState("");

  const [genderError, setGenderError] = useState("");
  const [paymentError, setPaymentError] = useState("");

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setGenderError("")
  };
  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
    setPaymentError("")
  };
  const [fNameTouch, setFNameTouch] = useState("");
  const [contactTouch, setContactTouch] = useState("");
  const [ageTouch, setAgeTouch] = useState("");
  const [cityTouch, setCityTouch] = useState("");


  const {
    hasError: hasErrorFName,
    enterValue: enterValueFName,
    message: FNameMessage,
    onChangeHandler: onChangeHandlerFName,
    reset: resetFName,
    isTouch: isTouchFName,
    onBlurHandler: onBlurHandlerFName,
  } = useInput(isFName, setNetworkError, setFNameTouch);


  const {
    hasError: hasErrorContact,
    enterValue: enterValueContact,
    message: ContactMessage,
    onChangeHandler: onChangeHandlerContact,
    reset: resetContact,
    isTouch: isTouchContact,
    onBlurHandler: onBlurHandlerContact,
  } = useInput(isContact, setNetworkError, setContactTouch);

  const {
    hasError: hasErrorAge,
    enterValue: enterValueAge,
    message: AgeMessage,
    onChangeHandler: onChangeHandlerAge,
    reset: resetAge,
    isTouch: isTouchAge,
    onBlurHandler: onBlurHandlerAge,
  } = useInput(isAge, setNetworkError, setAgeTouch);

  const {
    hasError: hasErrorCity,
    enterValue: enterValueCity,
    message: CityMessage,
    onChangeHandler: onChangeHandlerCity,
    reset: resetCity,
    isTouch: isTouchCity,
    onBlurHandler: onBlurHandlerCity,
  } = useInput(isCity, setNetworkError, setCityTouch);

  useEffect(() => {
    setNetworkError("");
    setFNameTouch("");
    setContactTouch("");
    setAgeTouch("");
    setCityTouch("");
  }, [
    enterValueFName,
    enterValueAge,
    enterValueContact,
    enterValueCity,
  ]);


  const submitHandler = async () => {
    setIsLoading(true);
    setNetworkError("");
    setLoadingMessage("Creating an Entry...");
    if (
      hasErrorFName === true ||
      hasErrorAge === true ||
      hasErrorContact === true ||
      hasErrorCity === true
    ) {
      setIsLoading(false);
      setLoadingMessage("");
      setNetworkError("Please Fill All Fields Appropriately!");
      return false;
    } else if (
      !isTouchFName ||
      !isTouchAge ||
      !isTouchContact ||
      !isTouchCity

    ) {

      if (!isTouchFName) {
        setFNameTouch("Please Enter Your First Name!");
      }
    
      if (!isTouchAge) {
        setAgeTouch("Please Enter Your Age!");
      }  if (!isTouchContact) {
        setContactTouch("Please Enter Your Contact Number!");
      } 
      if (!isTouchCity) {
        setCityTouch("Please Enter Your City!");
      }
      setIsLoading(false);
      setLoadingMessage("");
      setNetworkError("Please Fill All Fields Appropriately!");
      return false;
    }
    if (!gender) {
      setIsLoading(false);
      setGenderError("Please Select Your Gender!")
      return false;
    }
    if (!payment) {
      setPaymentError("Please Select Payment Mode!")
      setIsLoading(false);
      return false;
    }
    try {
      const body = {
        fName: enterValueFName,
        age: parseInt(enterValueAge),
        gender: gender,
        contact: parseInt(enterValueContact),
        city: enterValueCity,
        payId: payment,
      };
   const token = localStorage.getItem("beenayi_token");
            const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/reception/register-patients", "post", body,headers);
      if (response.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setIsLoading(false);
        setSuccessMessage(response.data.message);
        navigate("/registrationDashboard");
        toast.success("Successfully Added An Entry!");


      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };


  const resetfield = () => {
    setNetworkError("");
    resetFName();
    resetAge();
    resetContact();
    resetCity();
    setFNameTouch("");
    setAgeTouch("");
    setContactTouch("");
    setCityTouch("");
    setPayment("");
    setGender("");
  };

  return (
    <>
    <section
      style={{
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // opacity: 0.8,
        // height: '100vh',

      }}
      className="md:h-[100vh] "
    >
    

      <div className="md:pt-30px] flex min-h-full flex-1 flex-col justify-center px-2 pb-20 pt-6 lg:px-8 border border-gray-800 rounded-lg shadow-md">
        <div className="w-full text-center flex justify-center items-center pt-2 pb-20">
          <SearchPatient 
          setFollowUpOpen={setFollowUpOpen}
          setPatientfollowUpData={setPatientfollowUpData}
          value={value} 
          setValue={setValue}
          />
        </div>
        <div className="w-full">
          <h2 className="-mt-10 text-center text-3xl font-bold leading-28 tracking-tight text-[#0d5781]">
            Registration Form
          </h2>
        </div>

        <div className="mt-4 w-full px-10 max-[640px]:px-4 sm:px-24 md:px-32 lg:px-44 xl:px-52  ">
          <div className="space-y-4">
            <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1 md:grid-cols-3">
              <div className="w-[100%] mt-3">
                <Input
                  type="text"
                  label={
                    <>
                      {" Full Name"}
                      <span className="text-red-600 font-bold"> *</span>
                    </>
                  }
                  placeholder="Enter First Name"
                  id="fname"
                  name="fname"
                  enterValue={enterValueFName}
                  onChangeHandler={onChangeHandlerFName}
                  hasError={hasErrorFName}
                  errorMessage={FNameMessage}
                  isTouch={fNameTouch}
                  setIsTouch={setFNameTouch}
                  onBlurHandler={onBlurHandlerFName}
                />
              </div>

             
              <div className="w-[100%] mt-3">
                <Input
                  type="text"
                  label={
                    <>
                      {"Age"}
                      <span className="text-red-600 font-bold"> *</span>
                    </>
                  }
                  placeholder="Enter Last Name"
                  id="Age"
                  name="Age"
                  enterValue={enterValueAge}
                  onChangeHandler={onChangeHandlerAge}
                  hasError={hasErrorAge}
                  errorMessage={AgeMessage}
                  isTouch={ageTouch}
                  setIsTouch={setAgeTouch}
                  onBlurHandler={onBlurHandlerAge}
                />
              </div>
              <div className="w-[100%] mt-3">
                <div className="flex items-center justify-between mb-2">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-bold leading-6 text-gray-900"
                  >
                    Gender
                    <span className="text-red-600 font-bold"> *</span>
                  </label>
                </div>

                <select
                  id="gender"
                  name="gender"
                  value={gender}
                  onChange={handleGenderChange}
                  className="block w-full rounded-md border border-gray-300 py-[10px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {genderError && (
                  <div className="flex ml-[0.5px]">
                    <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                    <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                      {genderError}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1 md:grid-cols-3">
              
           
              <div className="w-[100%] mt-3">
                <Input
                  type="text"
                  label={
                    <>
                      {"Contact Number"}
                      <span className="text-red-600 font-bold"> *</span>
                    </>
                  }
                  placeholder="Enter Last Name"
                  id="Contact"
                  name="Contact"
                  enterValue={enterValueContact}
                  onChangeHandler={onChangeHandlerContact}
                  hasError={hasErrorContact}
                  errorMessage={ContactMessage}
                  isTouch={contactTouch}
                  setIsTouch={setContactTouch}
                  onBlurHandler={onBlurHandlerContact}
                />
              </div>
              <div className="w-[100%] mt-3">
                <Input
                  type="text"
                  label={
                    <>
                      {"City"}
                      <span className="text-red-600 font-bold"> *</span>
                    </>
                  }
                  placeholder="Enter City Name"
                  id="City"
                  name="City"
                  enterValue={enterValueCity}
                  onChangeHandler={onChangeHandlerCity}
                  hasError={hasErrorCity}
                  errorMessage={CityMessage}
                  isTouch={cityTouch}
                  setIsTouch={setCityTouch}
                  onBlurHandler={onBlurHandlerCity}
                />
              </div>
              <div className="w-[100%] mt-3">
                <div className="flex items-center justify-between mb-2">
                  <label
                    htmlFor="password"
                    className="block text-sm font-bold leading-6 text-gray-900"
                  >
                    Payment Mode
                    <span className="text-red-600 font-bold"> *</span>
                  </label>
                </div>

                <select
                  id="payment"
                  name="payment"
                  value={payment}
                  onChange={handlePaymentChange}
                  className="block w-full rounded-md border border-gray-300 py-[10px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                >
                  <option value="" disabled>
                    Select Payment Mode
                  </option>
                  <option value="1">Cash</option>
                  <option value="2">UPI</option>
                  {/* <option value="3">Follow-Up</option> */}
                  <option value="4">Complimentary</option>
                </select>
                {paymentError && (
                  <div className="flex ml-[0.5px]">
                    <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                    <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                      {paymentError}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="text-center">
              {isLoading && (
                <strong className="text-blue-800 text-sm  text-center">
                  {loadingMessage}
                </strong>
              )}
              {networkError && (
                <strong className="text-red-600 text-sm  text-center">
                  {networkError}
                </strong>
              )}
              {successMessage && (
                <strong className="text-green-800 text-sm  text-center">
                  {successMessage}
                </strong>
              )}
            </div>
            <div className="w-full text-center flex items-center justify-center">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
                <div>
                  <button
                    type="submit"
                    onClick={() => submitHandler()}
                    disabled={isLoading}
                    className={`  flex w-full justify-center rounded-md bg-[#0d5781] px-3 py-1.5  text-base font-semibold leading-6 text-white shadow-sm hover:bg-[#70a9ca] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#10a659] ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                      }
              }`}
                  >

                    {isLoading === true ?
                      (
                        <MoonLoader
                          color="#0d5781"
                          loading={isLoading}
                          size={20}
                          className="mx-1"
                        />
                      ) : (

                        <>Register Patient</>
                      )}
                  </button>
                </div>
                <div>
                  <Link to="/registrationDashboard">
                    <button
                      type="button"
                      className="flex w-full justify-center rounded-md bg-[#0d5781] px-3 py-1.5 text-base font-semibold leading-6 text-white shadow-sm hover:bg-[#70a9ca] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#10a659]"
                    >
                      View All
                    </button>
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    className="flex w-full justify-center rounded-md bg-red-700 px-3 py-1.5 text-base font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#a62610]"
                    onClick={() => resetfield()}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
    <PatientFollowUp
    patientfollowUpData={patientfollowUpData}
     followUpOpen={followUpOpen}
     setFollowUpOpen={setFollowUpOpen}
     patientId={patientfollowUpData._id}
     value={value} setValue={setValue}
    />
    </>
  );
}
