/** @format */

const CryptoJS = require("crypto-js");

export const APP_KEY = "beenayi_clinic";

export const encrypt = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    APP_KEY
  ).toString();

  return encodeURIComponent(encrypted);
};

export const decrypt = (encryptedData) => {
  const decodedData = decodeURIComponent(encryptedData);
  const decryptedData = CryptoJS.AES.decrypt(decodedData, APP_KEY).toString(
    CryptoJS.enc.Utf8
  );
  return JSON.parse(decryptedData);
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};
// ====================================================vission details array================================================
export const roles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Developer" },
  { id: 3, name: "Optometrist" },
  { id: 4, name: "Receptionist" },
];


export const visionOptions = [
  "6/6",
  "6/9",
  "6/12",
  "6/18",
  "6/24",
  "6/36",
  "6/60",
  "5/60",
  "4/60",
  "3/60",
  "2/60",
  "1/60",
  "CFCF (Counting Fingers)",
  "HM (Hand Movement)",
  "PL (Perception of Light)",
  "NPL (No Perception of Light)",
];

export const complaintOptions = [
  { id: "Blurry_vision", label: "Blurry Vision" },
  { id: "Eye_strain", label: "Eye Strain" },
  { id: "Headaches", label: "Headaches" },
  { id: "Double_vision", label: "Double Vision" },
  { id: "Dry_eye", label: "Dry Eye" },
  { id: "Eye_pain", label: "Eye Pain" },
  { id: "Light_sensitivity", label: "Light Sensitivity" },
  { id: "D_o_v", label: "D.O.V" },
  { id: "Watering", label: "Watering" },
  { id: "Discharge", label: "Discharge" },
  { id: "Itching", label: "Itching" },
  { id: "Redness", label: "Redness" },
  // { id: "routine_eye_check-up", label: "Routine" },
];
export const syringingOptions = [
  { id: "Patent", label: "Patent" },
  { id: "Partially Patent", label: "Partially Patent" },
  { id: "Blocked", label: "Blocked" },
  { id: "Upper patent, lower blocked", label: "Upper patent, lower blocked" },
  { id: "Lower patent, Upper blocked", label: "Lower patent, Upper blocked" },
  { id: "Not Performed", label: "Not Performed" },
];

export const eyeComplaitsOptions = [
  { id: "Left Eye", label: "Left Eye" },
  { id: "Right Eye", label: "Right Eye" },
  { id: "Both Eye", label: "Both Eye" },
];

export const visionCheckBoxList = [
  "Routine check-up",
  "Blurry Vision",
  "Eye Strain",
  "Headache",
  "Double Vision",
  "Dry Eye",
  "Eye Pain",
  "Light Sensitivity",
  "D.O.V",
  "Watering",
  "Discharge",
  "Itching",
  "Redness",
  "Allergic Conjunctivitis",
  "Allergy",
  "Injury",
  "Dizziness",
  "Infection",
  "Burning",
  "Post-op",
  "Foreign Body",
  "Flashes of Light",
  "Floaters",
  "Swelling",
  "Double Vision",
  "Follow-up",

];

export const glassesCheckBoxList = [
  "Medicine",
  "Glasses",
  "Cataract",
  "Lasik",
  "Check-up",
];
// ====================================================vission details end here================================================


