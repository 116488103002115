
/** @format */

import React, { useState, useEffect } from "react";
import { scrollToTop } from "../../../service/utils";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "../../components/admin-tabs/Tabs";
import TableHead from "../../components/table-formate/TableHead";
import TableBody from "../../components/table-formate/TableBody";

const DashboardDetailHistory = () => {
    const [activeTab, setActiveTab] = useState(0);

    let bookingActiveTab = JSON.parse(localStorage.getItem("ActiveTabs"));
    let getTodayAllData = JSON.parse(localStorage.getItem("TodayAllData"));
    let getOpdFollowData = JSON.parse(localStorage.getItem("opdFollowup"));
    let getPendingData = JSON.parse(localStorage.getItem("pendingData"));
    let getCancelData = JSON.parse(localStorage.getItem("cancelData"));
    let getFirstVisitData = JSON.parse(localStorage.getItem("firstVisitData"));
    let getCashUpiData = JSON.parse(localStorage.getItem("cashUpiData"));
    let getComplementryData = JSON.parse(localStorage.getItem("complimentaryData"));

    useEffect(() => {
        if (bookingActiveTab) {
            setActiveTab(bookingActiveTab);
            localStorage.removeItem("ActiveTabs");
        }
        scrollToTop()
    }, [bookingActiveTab]);

  


    return (
        <div>
            {/* {isLoading ? (
                <div className="flex justify-center h-screen items-center">
                    <PropagateLoader color="#36d7b7" loading={true} size={15} />
                </div>
            ) : networkError ? (
                <div className="text-red-600 text-center mt-16 font-semibold">
                    {networkError}
                </div>
            ) : ( */}
            <>
                <div className="flex flex-col mt-12 mb-10 mx-8">
                    <div className="overflow-x-auto ">
                        <h2 className="inline-block min-w-[97%] ml-2  text-3xl leading-tight my-1 border-b">
                            DashBoard All Details
                        </h2>
                        <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
                            <Tab label={`Today's (${getTodayAllData?.length})`}>
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[10px] font-light ">
                                           
                                            <TableHead/>
                                           
                                            <TableBody getData={getTodayAllData}/>
                                        </table>
                                        {/* {currentItemsToday && currentItemsToday.length > 0 ? (
                                            <>
                                                <div className="px-2 py-5 bg-white border-t flex flex-row max-[640px]:flex-col">
                                                    <div className="flex-initial w-[70%] sm:w-[35%] md:w-[60%] lg:w-[90%] xl:w-[98%]">
                                                        <span className="text-xs xs:text-sm text-gray-900">
                                                            Total {getAllBookingHistoryToday.length} Entries
                                                        </span>
                                                    </div>
                                                    <div className="flex items-end justify-end">
                                                        <button
                                                            className="text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-l"
                                                            onClick={() =>
                                                                paginateToday(currentPageToday - 1)
                                                            }
                                                            disabled={currentPageToday === 1}
                                                        >
                                                            Prev
                                                        </button>
                                                        {pageNumbersToday.map((number) => (
                                                            <button
                                                                key={number}
                                                                onClick={() => paginateToday(number)}
                                                                className={`text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 ${currentPageToday === number
                                                                    ? "bg-blue-500 text-black border-[1px] border-gray-400"
                                                                    : "text-gray-800"
                                                                    }`}
                                                            >
                                                                {number}
                                                            </button>
                                                        ))}
                                                        <button
                                                            className="text-sm bg-slate-200  hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-r"
                                                            onClick={() =>
                                                                paginateToday(currentPageToday + 1)
                                                            }
                                                            disabled={
                                                                currentPageToday === pageNumbersToday.length
                                                            }
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null} */}
                                    </div>
                                </div>
                            </Tab>
                            <Tab label={`OPD F.Up (${getOpdFollowData?.length})`}>
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[12px] font-light ">
                                        <TableHead/>
                                        <TableBody getData={getOpdFollowData}/>
                                        </table>
                                        
                                    </div>
                                </div>
                            </Tab>
                            <Tab label={`Pending (${getPendingData?.length})`}>
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[12px] font-light ">
                                        <TableHead/>
                                         <tbody className="bg-white divide-y divide-gray-200">
                                                    {getPendingData && getPendingData?.length > 0 ? (
                                                        <>
                                                            {getPendingData &&
                                                                getPendingData?.length > 0 &&
                                                                getPendingData?.map((item, index) => {
                                        
                                                                    return (
                                                                        <>
                                        
                                                                            <tr
                                                                                key={index}
                                                                                className="hover:text-white hover:bg-slate-100"
                                                                            >
                                                                                <td className="px-4 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {item?.patientId?.fName}{" "}
                                                                                </td>
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {item?.patientId?.age}
                                                                                </td>
                                        
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {item?.patientId?.gender}
                                                                                </td>
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {item?.patientId?.contact}
                                                                                </td>
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {item?.patientId?.payId === 1 ? (
                                                                                        <>
                                                                                            <div className="text-green-500">Cash</div>
                                                                                        </>
                                                                                    ) : item?.patientId?.payId === 2 ? (
                                                                                        <>
                                                                                            <div className="text-orange-500">UPI</div>
                                                                                        </>
                                                                                    ) : item?.patientId?.payId === 3 ? (
                                                                                        <>
                                                                                            <div className="text-yellow-500">Follow up</div>
                                                                                        </>
                                                                                    ) : item?.patientId?.payId === 4 ? (
                                                                                        <>
                                                                                            <div className="text-pink-800">Complimentary</div>
                                                                                        </>
                                                                                    ) : null}
                                                                                </td>
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    {item?.patientId?.city}
                                                                                </td>
                                                                                <td className="px-2 py-2 whitespace-normal text-sm text-gray-900">
                                                                                    <Link
                                                                                        to={`/admin/dashboard/patient-detailbyid/${item?.patientId?._id}`}
                                                                                        target="_blank"
                                                                                    >
                                        
                                                                                        View Details
                                                                                    </Link>
                                        
                                        
                                        
                                                                                </td>
                                        
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                        </>
                                                    ) : (
                                                        <td colSpan={8}>
                                                            <div className="text-red-400 text-center text-[16px] font-semibold my23">
                                                                No Detail Received.
                                                            </div>
                                                        </td>
                                                    )}
                                                </tbody>
                                           
                                        </table>
                                       
                                    </div>
                                </div>
                            </Tab>
                            
                            <Tab
                                label={`Cancel (${getCancelData?.length})`}
                            >
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[12px] font-light ">
                                        <TableHead/>
                                        <TableBody getData={getCancelData}/>

                                            
                                        </table>
                                        
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                label={`First Visit (${getFirstVisitData?.length})`}
                            >
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[12px] font-light ">
                                        <TableHead/>
                                        <TableBody getData={getFirstVisitData}/>

                                           
                                        </table>
                                       
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                label={`Cash UPI (${getCashUpiData?.length})`}
                            >
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[12px] font-light ">
                                        <TableHead/>
                                        <TableBody getData={getCashUpiData}/>

                                          
                                        </table>
                                        
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                label={`Complimentry (${getComplementryData?.length})`}
                            >
                                <div className="inline-block min-w-[97%] ml-2  border-[1px] border-slate-300 md:overflow-auto">
                                    <div className="overflow-hidden">
                                        <table className="w-[100%]  text-[12px] font-light ">
                                        <TableHead/>
                                        <TableBody getData={getComplementryData}/>
                                        </table>
                                        
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </>
            {/* )} */}
        </div>
    );
};

export default DashboardDetailHistory;
